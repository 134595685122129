const lazyLoading = function() {
    const isSmallScreen = window.matchMedia('(max-width: 480px)').matches;
    const isMediumScreen = window.matchMedia('(min-width: 481px) and (max-width: 1023px)').matches;
    const isLargeScreen = window.matchMedia('(min-width: 1024px)').matches;
    const images = $('[data-src]');
    images.each(function () {
        const image = $(this);
        const offsetTop = this.getBoundingClientRect().top
        const windowHeight = $(window).height();
        if (offsetTop <= (windowHeight * 1.5)) {
            let imageUrl = image.attr('data-src');
            const isGif = imageUrl.indexOf('.gif') !== -1;
            if (isSmallScreen && image.attr('data-size-sm') && image.attr('data-loaded') !== 'sm' && !isGif) {
                if (image.attr('data-size-sm') !== 'hidden') {
                    imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-sm')}`;
                    image.attr('data-loaded', 'sm');
                    if (image.is('img')) {
                        image.attr('src', imageUrl);
                    } else {
                        image.css('background-image', `url("${imageUrl}")`);
                    }
                }
            } else if (isMediumScreen && image.attr('data-size-md') && image.attr('data-loaded') !== 'md' && !isGif) {
                if (image.attr('data-size-md') !== 'hidden') {
                    imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-md')}`;
                    if (image.is('img')) {
                        image.attr('src', imageUrl);
                    } else {
                        image.css('background-image', `url("${imageUrl}")`);
                    }
                    image.attr('data-loaded', 'md');
                }
            } else if (isLargeScreen && image.attr('data-size-lg') && image.attr('data-loaded') !== 'lg' && !isGif) {
                if (image.attr('data-size-lg') !== 'hidden') {
                    imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-lg')}`;
                    image.attr('data-loaded', 'lg');
                    if (image.is('img')) {
                        image.attr('src', imageUrl);
                    } else {
                        image.css('background-image', `url("${imageUrl}")`);
                    }
                }
            } else if (!image.attr('data-loaded')) {
                image.attr('data-loaded', true);
                if (image.is('img')) {
                    image.attr('src', imageUrl);
                } else {
                    image.css('background-image', `url("${imageUrl}")`);
                }
            }
        }
    });
};

export { lazyLoading };