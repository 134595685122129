import { Form } from "../form/form";

const pressForm = function() {
    if ($('#pressForm').length) {
        const pressForm = new Form(
            '#pressForm', 
            '#pressFormSuccessMessage', 
            '#pressFormErrorMessage',
            [],
            {
                City: "",
                ComplaintFiles: [],
                Country: "",
                Email: "",
                Message: "",
                Name: "",
                Phone: "",
                ProductName: "",
                ReceiveInformation: false,
                Region: "",
                SubjectPrimary: "",
                SubjectPrimaryId: "",
                SubjectSecondary: ""
            }
        );
        pressForm.init();
    } 
};

export default pressForm;