import Swiper from "swiper/dist/js/swiper.min";
const debounce = require('debounce');

function load(image) {
    const isMobile = window.matchMedia('(max-width: 639px)').matches;
    const isSmallScreen = window.matchMedia('(max-width: 480px)').matches;
    const isMediumScreen = window.matchMedia('(min-width: 481px) and (max-width: 1023px)').matches;
    const isLargeScreen = window.matchMedia('(min-width: 1024px)').matches;
    let imageUrl = isMobile ? image.attr('data-bg-mobile') : image.attr('data-bg-desktop');
    const isGif = imageUrl.indexOf('.gif') !== -1;
    const offsetTop = image[0].getBoundingClientRect().top
    const windowHeight = $(window).height();
    if (offsetTop <= (windowHeight * 1.5)) {
        if (isSmallScreen && image.attr('data-size-sm') && image.attr('data-loaded') !== 'sm' && !isGif) {
            if (image.attr('data-size-sm') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-sm')}`;
                image.attr('data-loaded', 'sm');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isMediumScreen && image.attr('data-size-md') && image.attr('data-loaded') !== 'md' && !isGif) {
            if (image.attr('data-size-md') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-md')}`;
                image.attr('data-loaded', 'md');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isLargeScreen && image.attr('data-size-lg') && image.attr('data-loaded') !== 'lg' && !isGif) {
            if (image.attr('data-size-lg') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-lg')}`;
                image.attr('data-loaded', 'lg');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (!image.attr('data-loaded')) {
            image.attr('data-loaded', true);
            image.css('background-image', `url("${imageUrl}")`);
        }
    }
}

function zoomIn(image) {
    image.addClass('zoom-in');
}

function zoomOut(image) {
    image.removeClass('zoom-in');
}

const headerSlider = function () {
    $(".header-slider .swiper-container").each(function () {
        const swiperContainer = $(this);
        swiperContainer.css('height', `${$(window).innerHeight()}px`);
        $(window).on('resize', debounce(function () {
            swiperContainer.css('height', `${$(window).innerHeight()}px`);
        }, 50));

        const numberOfSlides = swiperContainer.find('.swiper-slide').length;
        if (numberOfSlides > 1) {
            const headerSwiper = new Swiper(swiperContainer, {
                init: false,
                autoplay: true,
                speed: 1000,
                effect: "fade",
                loop: true,
                slidesPerView: 1,
                a11y: true,
                a11y: {
                    prevSlideMessage: "Previous slide",
                    nextSlideMessage: "Next slide",
                    firstSlideMessage: "This is the first slide",
                    lastSlideMessage: "This is the last slide",
                    paginationBulletMessage: "Go to slide {{index}}",
                },
                pagination: {
                    el: swiperContainer.parents('.header-slider').find(".swiper-pagination"),
                },
                navigation: {
                    nextEl: swiperContainer.parents('.header-slider').find(".swiper-button-next"),
                    prevEl: swiperContainer.parents('.header-slider').find(".swiper-button-prev"),
                },
                on: {
                    slideNextTransitionStart: function () {
                        if (headerSwiper) {
                            swiperContainer.find('.swiper-slide').each(function () {
                                const image = $(this).find('.img');
                                const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                                const disableZoom = parseInt($(this).attr('data-disable-zoom'));
                                const activeSlideIndex = headerSwiper.realIndex;
                                let nextSlideIndex = activeSlideIndex === numberOfSlides - 1 ? 0 : activeSlideIndex + 1;
                                if (slideIndex === nextSlideIndex) {
                                    load(image);
                                }

                                if (disableZoom === 1) {
                                    return;
                                }

                                if (slideIndex === activeSlideIndex) {
                                    zoomIn(image);
                                } else {
                                    zoomOut(image);
                                }
                            });

                        }
                    },
                    slidePrevTransitionStart: function () {
                        if (headerSwiper) {
                            swiperContainer.find('.swiper-slide').each(function () {
                                const image = $(this).find('.img');
                                const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                                const disableZoom = parseInt($(this).attr('data-disable-zoom'));
                                const activeSlideIndex = headerSwiper.realIndex;
                                let prevSlideIndex = activeSlideIndex === 0 ? numberOfSlides - 1 : activeSlideIndex - 1;
                                if (slideIndex === prevSlideIndex) {
                                    load(image);
                                }

                                if (disableZoom === 1) {
                                    return;
                                }

                                if (slideIndex === activeSlideIndex) {
                                    zoomIn(image);
                                } else {
                                    zoomOut(image);
                                }
                            });
                        }
                    },
                    resize: function () {
                        swiperContainer.find('.swiper-slide').each(function (i) {
                            const image = $(this).find('.img');
                            const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                            const activeSlideIndex = headerSwiper.realIndex;
                            let nextSlideIndex = activeSlideIndex === numberOfSlides - 1 ? 0 : activeSlideIndex + 1;
                            if (slideIndex === activeSlideIndex || slideIndex === nextSlideIndex) {
                                load(image);
                            }
                        });
                    }
                }
            });

            headerSwiper.on('init', function () {
                swiperContainer.find('.swiper-slide').each(function (i) {
                    const image = $(this).find('.img');
                    const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                    const disableZoom = parseInt($(this).attr('data-disable-zoom'));
                    const activeSlideIndex = headerSwiper.realIndex;
                    const lastSlideIndex = numberOfSlides - 1;
                    let nextSlideIndex = activeSlideIndex === numberOfSlides - 1 ? 0 : activeSlideIndex + 1;
                    if (slideIndex === activeSlideIndex || slideIndex === nextSlideIndex || slideIndex === lastSlideIndex) {
                        load(image);
                    }

                    if (disableZoom === 1) {
                        return;
                    }

                    if (slideIndex === headerSwiper.realIndex) {
                        zoomIn(image);
                    }
                });
            });

            headerSwiper.init();

            swiperContainer.parents('.header-slider').find('.header-slider__next-wrapper').fadeIn(300);
            swiperContainer.parents('.header-slider').find('.header-slider__prev-wrapper').fadeIn(300);
        } else {
            const image = swiperContainer.find('.swiper-slide .img');
            load(image);
        }
    })
};



export default headerSlider;
