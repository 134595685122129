const navigation = () => {
    let scrollPosition;
    
    if (window.pageYOffset > 0) {
        $('.nav-logo').addClass('nav-logo--shrinked');
        $('nav').addClass('nav-white');
    } else {
        if (!$('body').hasClass('no-scroll') && !$('body').hasClass('no-scroll-desktop')) {
            $('.nav-logo').removeClass('nav-logo--shrinked');
            $('nav').removeClass('nav-white');
        }
    }

    $('[data-nav-mobile=open]').click(function (e) {
        e.preventDefault();
        $('[data-nav=mobile]').addClass('open');
        scrollPosition = document.documentElement.scrollTop;
        $('body').addClass('no-scroll');
        $('body').css('top', `-${scrollPosition}px`);
    });

    $('[data-nav-mobile=close]').click(function (e) {
        e.preventDefault();
        $('[data-nav=mobile]').removeClass('open');
        $('body').removeClass('no-scroll');
        setTimeout(function() {
            $('body').css('top', 'auto');
            document.documentElement.scrollTop = scrollPosition;
        }, 10);
    });

    $('[data-nav-mobile=open-lev-2]').click(function () {
        $(this).find('svg').toggleClass('active');
        $(this).parents('[data-nav-mobile=lev-1]').find('[data-menu-mobile=lev-1]').slideToggle();
    });

    $(window).on("scroll", function () {
        if (window.pageYOffset > 0) {
            $('.nav-logo').addClass('nav-logo--shrinked');
            $('nav').addClass('nav-white');
        } else {
            if (!$('body').hasClass('no-scroll') && !$('body').hasClass('no-scroll-desktop')) {
                $('.nav-logo').removeClass('nav-logo--shrinked');
                $('nav').removeClass('nav-white');
            }
        }
    });
};

export default navigation;