const stickyMenuWidget = () => {
    const stickyMenu = document.querySelector(".sticky-menu");
    if (!stickyMenu) return;
    var stickyMenuParent = stickyMenu.closest("div.py-64");
    if (stickyMenuParent === null){
        stickyMenuParent = stickyMenu.parentElement;
    }
    const elementRect = stickyMenuParent.getBoundingClientRect();
    stickyMenuParent.style.minHeight = `${elementRect.height}px`;

    if (elementRect.top >= -8) {
        stickyMenu.classList.remove("sticky-menu--sticky");
    } else {
        stickyMenu.classList.add("sticky-menu--sticky");
    }

    function scrollToElement(e) {
        e.preventDefault();
        if (!e.target instanceof HTMLAnchorElement) return;
        const link = e.target;
        const content = document.getElementById(link.href.split("#")[1]);
        window.scrollTo({
            top: window.scrollY + content?.getBoundingClientRect().top - 200,
            left: 0,
            behavior: "smooth",
        });
    }

    stickyMenu.addEventListener("click", scrollToElement);
};

export default stickyMenuWidget;
