const hero2 = function () {

    function setBackgroundHeight() {
        $('.js-hero2').each(function () {
            const hero2 = $(this);
            const hero2Height = hero2.innerHeight();
            const imgSmallHeight = hero2.find('.js-hero-small-img').innerHeight();
            let hero2OffsetHeight;
            if (hero2.hasClass('hero2-bg-cropped-bot')) {
                hero2OffsetHeight = hero2.find('.js-hero-small-img').offset().top - hero2.offset().top + imgSmallHeight / 2;
            }
            if (hero2.hasClass('hero2-bg-cropped-top') && !hero2.hasClass('hero2-bg-cropped-bot')) {
                hero2OffsetHeight = hero2Height;
            }
            if (window.matchMedia('(max-width: 1023px)').matches && hero2.hasClass('hero2-red-square') && hero2.hasClass('hero2-bg-cropped-top')) {
                hero2OffsetHeight = hero2Height - parseInt($(this).css("padding-top")) - 64;
                hero2.find('.bg').css('top', 'auto').css('bottom', '0px');
            } else {
                hero2.find('.bg').css('bottom', 'auto').css('top', '0px');
            }
            hero2.find('.bg').css('height', hero2OffsetHeight + 'px');
        });
    }
    
    setTimeout(function () {
        setBackgroundHeight();
    }, 100);
};

export default hero2;