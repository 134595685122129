const checkIfStepIsValid = (stepId) => {
    const formContainer = $('.js-multistep-form');
    const fields = formContainer.find(`#${stepId} .form-field`);
    const fieldsArray = fields.toArray();
    const fieldsWithInputs = fieldsArray.filter(
        (field) =>
            $(field).find("[data-ktc-notobserved-element][required]").length ||
            $(field).find("[data-ktc-notobserved-element][data-required]").length
    );

    for (let i = 0; i < fieldsWithInputs.length; i++) {
        const fieldInputs = $(fieldsWithInputs[i])
            .find("[data-ktc-notobserved-element]")
            .toArray()
            .filter((input) => $(input).attr("required") || $(input).attr("data-required"));
        const input = fieldInputs[0];

        // checkboxes
        if ($(input).is(":checkbox")) {
            let allChecboxUnchecked = true;
            for (let j = 0; j < fieldInputs.length; j++) {
                const input = $(fieldInputs[j]);
                if (input.is(":checked")) {
                    fieldInputs.map((input) => {
                        $(input).removeAttr("required");
                    });
                    allChecboxUnchecked = false;
                    break;
                }
            }
            if (allChecboxUnchecked) {
                return false;
            }
            // email input
        } else if ($(input).attr("type") === "email") {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test($(input).val())) {
                return false;
            }
        } else {
            if ($(input).val().length === 0) {
                return false;
            }
        }
    }

    return true;
};

const validateMultiStepForm = () => {
    const formContainer = $('.js-multistep-form');
    const numberOfSteps = formContainer.find("div[data-stepid]").length;
    let activeStep;
    formContainer.find("div[data-stepid]").each(function () {
        if ($(this).is(':visible')) {
            activeStep = parseInt($(this).attr('data-stepid').replace(/[^0-9]/g, ''))
        }
    });

    if (checkIfStepIsValid(`step-${activeStep}`)) {
        $("#form-barista-next-step").removeClass("btn--disabled");
        if (activeStep === numberOfSteps) {
            formContainer.find("input[type=submit]").removeClass("btn--disabled");
        }
    } else {
        $("#form-barista-next-step").addClass("btn--disabled");
        if (activeStep === numberOfSteps) {
            formContainer.find("input[type=submit]").addClass("btn--disabled");
        }
    }
}

const baristaCampaign = function () {
    let activeStep = 1;
    const formContainer = $('.js-multistep-form');
    if (!formContainer) return;
    
    const numberOfSteps = formContainer.find("div[data-stepid]").length;
    const fileInputs = formContainer.find("input[type=file]");
    let nextStepText = "Next step";
    let submitBtnLoadingText = "Sending...";
    const nextStepInput = formContainer.find("input[type=hidden][data-next-step-btn-text]");
    const submitBtnLoadingInput = formContainer.find("input[type=hidden][data-submit-btn-loading-text]");
    if (nextStepInput.length > 0){
        nextStepText = nextStepInput.attr("data-next-step-btn-text");
    }
    if (submitBtnLoadingInput.length > 0){
        submitBtnLoadingText = submitBtnLoadingInput.attr("data-submit-btn-loading-text");
    }

    fileInputs.parents("div[data-stepid]").addClass("step-file-upload");
    // Hide form submit button
    formContainer.find("input[type=submit]").addClass("btn btn--primary btn--disabled");

    // Render Steps indicator
    const createStepsIndicator = () => {
        formContainer.find(`#step-${1}`).show();
        formContainer.find("div[data-stepid=step-1]").before('<div class="steps-indicator"></div>');
        for (let step = 1; step <= numberOfSteps; step++) {
            formContainer
                .find(".steps-indicator")
                .append(
                    `<div class="step${activeStep === step ? " step--active" : ""}">${step}</div>`
                );
        }
    };
    createStepsIndicator();

    formContainer.find(".ktc-checkbox-list").each(function () {
        $(this)
            .find("[data-ktc-notobserved-element][required]")
            .each(function () {
                $(this).attr("data-required", "true");
            });
    });

    // Render Next Step button
    formContainer.append(`
        <div class="w-full flex justify-center mt-40">
            <button id="form-barista-next-step" class="btn btn--primary btn--arrow-right btn--disabled">
                <span>${nextStepText}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.02 8.82">
                    <path xmlns="http://www.w3.org/2000/svg" d="M13.86.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41l1.71,1.7H1a1,1,0,0,0,0,2H14.16l-1.71,1.7a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.7-.29L18,4.41Z"></path>
                </svg>
            </button>
        </div>
    `);

    function formatBytes(bytes) {
        if (bytes < 1024) {
            return bytes + " Bytes";
        } else if (bytes < 1024 * 1024) {
            return (bytes / 1024).toFixed(2) + " KB";
        } else {
            return (bytes / (1024 * 1024)).toFixed(2) + " MB";
        }
    }

    formContainer.find(".ktc-uploader-text-button").on("click", function () {
        $(this).parents(".form-field").find("input[type=file]").val("");
        $(this)
            .parents(".form-field")
            .find("input[type=file]")[0]
            .dispatchEvent(new Event("change"));
        validateMultiStepForm();
    });

    // Show file name and size when a file is selected
    fileInputs.on("change", function () {
        const fileInput = this;
        const inputPlaceholderText = $(this)
            .parents(".form-field")
            .children(".explanation-text")
            .text();
        if (fileInput.files.length > 0) {
            const selectedFile = fileInput.files[0];
            const fileName = selectedFile.name;
            const fileSize = selectedFile.size;
            const maxSize = parseInt($(fileInput).attr("data-maxsize"));
            if (fileSize <= maxSize) {
                $(this).parents(".form-field").children(".file-input-empty").hide();
                $(this)
                    .parents("[data-file-type]")
                    .find(".file-input-info")
                    .html(
                        `<span class="file-input-info-name">${fileName}</span>-<span class="file-input-info-size">${formatBytes(
                            fileSize
                        )}</span>`
                    );
            } else {
                $(this).val("");
                $(this)
                    .parents("[data-file-type]")
                    .find(".file-input-info")
                    .text(inputPlaceholderText);
                $(this).parents(".form-field").find('.ktc-uploader-text-button').attr('hidden', 'hidden')
                $(this).parents(".form-field").children(".file-input-empty").show();
            }
        } else {
            $(this).parents("[data-file-type]").find(".file-input-info").text(inputPlaceholderText);
            $(this).parents(".form-field").find('.ktc-uploader-text-button').attr('hidden', 'hidden')
            $(this).parents(".form-field").children(".file-input-empty").show();
            validateMultiStepForm();
        }
    });

    // Render SVG Icons for file inputs
    fileInputs.each(function () {
        const svgVideo = `
            <div class="file-input-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="70px" x="0px" y="0px" viewBox="0 0 90 68" style="enable-background:new 0 0 90 68;">
                    <style type="text/css">
                        .st0{fill:#CF002C;}
                    </style>
                    <g id="Group-6">
                        <path class="st0" d="M51.4,22.7l-20.1-10c-1-0.5-2.3-0.5-3.3,0.1c-1,0.6-1.6,1.7-1.6,2.8l0,21.9c0,1.2,0.6,2.3,1.7,2.9   c0.5,0.3,1.1,0.4,1.7,0.4c0.6,0,1.2-0.2,1.7-0.5l20.1-11.9c1.1-0.6,1.7-1.8,1.6-3C53.2,24.3,52.5,23.2,51.4,22.7z M49.9,25.6   L29.8,37.5l0-21.9L49.9,25.6z"/>
                        <path class="st0" d="M51.6,50.4H6.4c-1.2,0-2.2-1-2.2-2.2v-42c0-1.2,1-2.2,2.2-2.2h65c1.2,0,2.2,1,2.2,2.2v25.7   c1.2,0.2,2.4,0.6,3.5,1.1V6.2c0-3.2-2.6-5.8-5.8-5.8h-65C3.3,0.4,0.7,3,0.7,6.2v42c0,3.2,2.6,5.8,5.8,5.8h45.8   C51.9,52.8,51.7,51.6,51.6,50.4z"/>
                    </g>
                    <g id="Rectangle_00000018929763589660384800000017032489660523751353_">
                        <path class="st0" d="M70.2,68H69c-10.5,0-19-8.5-19-19s8.5-19,19-19h1.2c10.5,0,19,8.5,19,19S80.7,68,70.2,68z M69,33   c-8.8,0-16,7.2-16,16s7.2,16,16,16h1.2c8.8,0,16-7.2,16-16s-7.2-16-16-16H69z"/>
                    </g>
                    <path class="st0" d="M71.6,40.8c-0.9-0.9-2.3-0.9-3.2,0L63.2,46l1.8,1.8l3.8-3.8v14.2h2.5V44l3.8,3.8l1.8-1.8L71.6,40.8z"/>
                </svg>
            </div>
        `;
        const svgImage = `
            <div class="file-input-icon">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 77 74" style="enable-background:new 0 0 77 74;width: 90px;height: 74px;">
                    <style type="text/css">
                        .st0{fill:#CF002C;}
                    </style>
                    <path class="st0" d="M58.6,46.8c-0.9-0.9-2.3-0.9-3.2,0L50.2,52l1.8,1.8l3.8-3.8v14.2h2.5V50l3.8,3.8l1.8-1.8L58.6,46.8z"/>
                    <g>
                        <path class="st0" d="M57.2,35L57.2,35l0-16.9c0-2.4-1.9-4.3-4.3-4.3h-5.1l-6-6.7c-0.3-0.4-0.9-0.6-1.4-0.6H25.9   c-0.5,0-1,0.2-1.4,0.6l-5.8,6.7H6.2c-2.4,0-4.3,1.9-4.3,4.3v24.5c0,2.4,1.9,4.3,4.3,4.3h10.4c1,0,1.8-0.8,1.8-1.8   c0-1-0.8-1.8-1.8-1.8H6.2c-0.4,0-0.6-0.3-0.6-0.6V18.1c0-0.4,0.3-0.7,0.6-0.7h13.4c0.5,0,1-0.2,1.4-0.6l5.8-6.7h12.8l6,6.7   c0.4,0.4,0.9,0.6,1.4,0.6h5.9c0.4,0,0.6,0.3,0.6,0.7v17.1c-1.3,0.2-2.6,0.5-3.8,0.9c0.1-0.7,0.1-1.4,0.1-2.1   c0-9.2-7.4-16.6-16.6-16.6c-9.2,0-16.6,7.4-16.6,16.6c0,9.2,7.4,16.6,16.6,16.6c1.5,0,2.9-0.2,4.2-0.6c-0.3,1.3-0.4,2.6-0.4,4   c0,10.5,8.5,19,19,19h1.2c10.5,0,19-8.5,19-19S67.7,35,57.2,35z M33.2,46.9c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9   c7.1,0,12.9,5.8,12.9,12.9c0,1.5-0.3,2.9-0.7,4.3c-2.7,1.8-4.9,4.3-6.3,7.2C37.3,46.4,35.3,46.9,33.2,46.9z M57.2,70H56   c-8.8,0-16-7.2-16-16s7.2-16,16-16h1.2c8.8,0,16,7.2,16,16S66,70,57.2,70z"/>
                        <path class="st0" d="M7.4,21.1c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8S7.4,20.1,7.4,21.1z"/>
                        <path class="st0" d="M7.4,11.9h5.5c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H7.4c-1,0-1.8,0.8-1.8,1.8C5.5,11.1,6.4,11.9,7.4,11.9z   "/>
                        <path class="st0" d="M33.2,24.8c-5.1,0-9.2,4.1-9.2,9.2c0,5.1,4.1,9.2,9.2,9.2c5.1,0,9.2-4.1,9.2-9.2   C42.4,28.9,38.3,24.8,33.2,24.8z M33.2,39.5c-3,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5   C38.7,37.1,36.2,39.5,33.2,39.5z"/>
                    </g>
                </svg>
            </div>
        `;
        const fileType = $(this).attr("data-video")?.length ? "video" : "image";
        $(this).parents(".form-field").attr("data-file-type", fileType);
        $(this)
            .parents(".form-field")
            .children("label")
            .first()
            .prepend(fileType === "video" ? svgVideo : svgImage);
        const filePlaceholderText = $(this)
            .parents(".form-field")
            .children(".explanation-text")
            .text();
        $(this).parents(".form-field").children("label").first().append(`
            <div class="file-input-info">
                ${filePlaceholderText}
            </div>
        `);
        $(this).parents(".form-field").children(".explanation-text").after(`
                <div class="file-input-empty">
                    No file chosen
                </div>
            `);
    });

    // Navigate to next step
    $("#form-barista-next-step").on("click", function () {
        if (!checkIfStepIsValid(`step-${activeStep}`)) return;
        formContainer.find('.js-multistep-form-logo').hide();
        const nextStep = activeStep + 1;
        formContainer.find(`#step-${activeStep}`).hide();
        $(formContainer.find(".steps-indicator .step")[activeStep - 1]).removeClass("step--active");
        $(formContainer.find(".steps-indicator .step")[nextStep - 1]).addClass("step--active");
        formContainer.find(".steps-indicator");
        if (nextStep === numberOfSteps) {
            $("#form-barista-next-step").hide();
            formContainer.find("input[type=submit]").css("display", "block");
        } else {
            $("#form-barista-next-step").addClass("btn--disabled");
        }
        if (formContainer.find(`#step-${nextStep}`).find("input[type=file]").length > 0) {
            formContainer.find(`#step-${nextStep}`).css("display", "flex");
        } else {
            formContainer.find(`#step-${nextStep}`).show();
        }
        window.scrollTo(0, formContainer.offset().top - 100);
        activeStep = nextStep;
    });

    // trigger check form validity on every form input
    formContainer.find("form").on("input", function (event) {
        var targetElement = event.target;
        if ($(targetElement).attr('type') === 'file') return
        validateMultiStepForm();
    });

    formContainer.find("input[type=submit]").on("click", function () {
        $(this).addClass('submit-btn-loading');
        $(this).attr('value', submitBtnLoadingText);
    });
};

export { baristaCampaign, validateMultiStepForm };
