class UploadInput {
    constructor(inputName) {
        this.input = $(`input[name=${inputName}]`);
        this.inputName = inputName;
        this.uploadedFiles = [];
        this.filesList = this.input.parents('.upload-input').find('.js-files-list');
    }

    getUploadedFiles () {
        return this.uploadedFiles;
    }

    init() {
        const that = this;
        this.input.parents('.upload-input').find('.js-upload-button').on('keypress', function (e) {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                that.input.click();
            }
        });

        this.input.on('change', function() {
            const files = $(this)[0].files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileAlreadyUploaded = that.uploadedFiles.find(x => x.name === file.name);
                if (!fileAlreadyUploaded) {
                    if (
                        file.type.indexOf('image/jpg') > -1 ||
                        file.type.indexOf('image/jpeg') > -1 ||
                        file.type.indexOf('image/png') > -1 ||
                        file.type.indexOf('pdf') > -1
                    ) {
                        that.uploadedFiles.push(file);
                        const el = $(`
                            <div class="upload-input__file">
                                <p>${file.name}</p>
                                <svg tabindex="0" class="js-delete-file" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <defs>
                                        <style>.cls-1{fill:#191919;fill-rule:evenodd;}</style>
                                    </defs>
                                    <path class="cls-1" d="M15,5.69h.11A1,1,0,0,1,16,6.78l-.82,9.57A1.89,1.89,0,0,1,13.24,18H4.76a1.89,1.89,0,0,1-1.94-1.65L2,6.78a1,1,0,0,1,2-.17L4.8,16h8.4L14,6.61a1,1,0,0,1,1.09-.91ZM7,6A1,1,0,0,1,8,7v7a1,1,0,0,1-2,0V7A1,1,0,0,1,7,6Zm4,0a1,1,0,0,1,1,1v7a1,1,0,0,1-2,0V7A1,1,0,0,1,11,6Zm.3-6A1.64,1.64,0,0,1,13,1.58V3h4a1,1,0,0,1,0,2H1A1,1,0,0,1,1,3H5V1.58A1.65,1.65,0,0,1,6.7,0ZM11,2H7V3h4Z"/>
                                </svg>
                            </div>
                        `);
                        that.filesList.append(el);
                        
                        el.find('.js-delete-file').on('click', function () {
                            const fileIndex = $(this).parents('.upload-input__file').index();
                            const updatedFiles = that.uploadedFiles.filter((x, i) => i !== fileIndex);
                            that.uploadedFiles = updatedFiles;
                            $(this).parents('.upload-input__file').remove();
                        });

                        el.find('.js-delete-file').on('keyup', function (e) {
                            const keyCode = e.keyCode || e.which;
                            if (keyCode === 13) {
                                const fileIndex = $(this).parents('.upload-input__file').index();
                                const updatedFiles = that.uploadedFiles.filter((x, i) => i !== fileIndex);
                                that.uploadedFiles = updatedFiles;
                                $(this).parents('.upload-input__file').remove();
                            }
                        });
                        
                    }
                }
            }
        });
    }
}

export { UploadInput };