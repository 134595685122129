import Swiper from "swiper/dist/js/swiper.min";

const coffeeProductsFilters = function () {
    if ($('.js-filters-swiper').length) {
        const filtersSwiper = filtersSwiperInit();
        const products = $('.js-product');
        const categories = $('.js-category');
        const filterCheckboxes = $('.js-coffee-filter').find('input[type=checkbox]');
        const selectedFiltersState = {
            category: [],
            preparation: []
        };

        setOddAndEvenProducts();

        filterCheckboxes.on('change', function () {
            const checkbox = $(this);
            updateSelectedFiltersState(selectedFiltersState, checkbox);
            filterByCategories(selectedFiltersState, categories);
            filterByPreparation(selectedFiltersState, products);
            toggleDisplayOfNoResultMessage(categories);
            updateSelectedFiltersTags(filtersSwiper, selectedFiltersState);
            loadImages();
            setOddAndEvenProducts();
        });
    }
};

function setOddAndEvenProducts() {
    $('.products').each(function () {
        const visibleProducts = $(this).find('.js-product:visible');
        visibleProducts.removeClass('even').removeClass('odd');
        visibleProducts.each(function (i) {
            const product = $(this);
            if (i % 2 === 0) {
                product.addClass('even');
            } else {
                product.addClass('odd');
            }
        });
    });
}

function toggleDisplayOfNoResultMessage(categories) {
    let showNoProductsMessage = true;
    categories.each(function () {
        const category = $(this);
        let showCategory = false;
        const categoryProducts = [].slice.call(category.find('.js-product'));
        for (var i = 0; i < categoryProducts.length; i++) {
            const product = $(categoryProducts[i])
            if (product.is(':visible')) {
                showNoProductsMessage = false;
                showCategory = true;
                break;
            }
        }
        if (!showCategory) {
            category.hide();
        }
    });
    if (showNoProductsMessage) {
        $('.js-no-product-found').show();
    } else {
        $('.js-no-product-found').hide();
    }
}

function filtersSwiperInit() {
    const filtersSwiper = new Swiper($('.js-filters-swiper'), {
        slidesPerView: 'auto',
        init: false,
        on: {
            resize: function () {
                const swiper = $('.js-filters-swiper')[0].swiper;
                swiper.allowTouchMove = !(swiper.isBeginning && swiper.isEnd);
            },
            update: function () {
                const swiper = $('.js-filters-swiper')[0].swiper;
                swiper.allowTouchMove = !(swiper.isBeginning && swiper.isEnd);
            }
        }
    });
    filtersSwiper.on('init', function () {
        const swiper = $('.js-filters-swiper')[0].swiper;
        if (swiper.isBeginning && swiper.isEnd) {
            swiper.allowTouchMove = false;
        }
    });
    filtersSwiper.init();
    return filtersSwiper;
}

function updateSelectedFiltersState(selectedFiltersState, checkbox) {
    const filterName = checkbox.attr('id').split('-')[0];
    const optionId = checkbox.attr('id').split('-')[1];
    const optionName = checkbox.next('label').text();
    if (checkbox.is(':checked')) {
        selectedFiltersState[filterName].push({
            id: optionId,
            name: optionName
        });
    } else {
        for (var i = 0; i < selectedFiltersState[filterName].length; i++) {
            if (selectedFiltersState[filterName][i].id === optionId) {
                selectedFiltersState[filterName].splice(i, 1);
            }
        }
    }
    $('.js-coffee-filter-categories').find('.dropdown-filter__title span').text(selectedFiltersState.category.length);
    $('.js-coffee-filter-preparation').find('.dropdown-filter__title span').text(selectedFiltersState.preparation.length);
}

function filterByCategories(selectedFiltersState, categories) {
    if (selectedFiltersState.category.length === 0) {
        categories.show();
    } else {
        categories.each(function () {
            const category = $(this);
            let categoryFound = false;
            const categoryId = category.attr('data-category-id');
            for (var i = 0; i < selectedFiltersState.category.length; i++) {
                if (selectedFiltersState.category[i].id === categoryId) {
                    categoryFound = true;
                    break;
                }
            }
            if (categoryFound) {
                category.show();
            } else {
                category.hide();
            }
        });
    }
}

function filterByPreparation(selectedFiltersState, products) {
    if (selectedFiltersState.preparation.length === 0) {
        products.show();
    } else {
        products.each(function () {
            const product = $(this);
            let preparationFound = false;
            const productPreparationIds = product.attr('data-preparation-id') ? product.attr('data-preparation-id').split(',') : [];
            for (var j = 0; j < selectedFiltersState.preparation.length; j++) {
                if (productPreparationIds.indexOf(selectedFiltersState.preparation[j].id) !== -1) {
                    preparationFound = true;
                    break;
                }
            }
            if (preparationFound) {
                product.show();
            } else {
                product.hide();
            }
        });
    }
}

function updateSelectedFiltersTags(filtersSwiper, selectedFiltersState) {
    filtersSwiper.removeAllSlides();
    for (var i = 0; i < selectedFiltersState.category.length; i++) {
        const category = selectedFiltersState.category[i];
        const filterTag = $(`
            <div class="swiper-slide filter-tag" data-category-id="${category.id}">
                ${category.name}
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" x="0px" y="0px" viewBox="0 0 16 16" fill="#9c958b">
                    <g>
                        <path d="M8,0C3.589,0,0,3.589,0,8s3.589,8,8,8s8-3.589,8-8S12.411,0,8,0z M8,14c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6    S11.309,14,8,14z"/>
                        <polygon points="10.293,4.293 8,6.586 5.707,4.293 4.293,5.707 6.586,8 4.293,10.293 5.707,11.707 8,9.414 10.293,11.707     11.707,10.293 9.414,8 11.707,5.707   "/>
                    </g>
                </svg>
            </div>
        `);
        filtersSwiper.appendSlide(filterTag);
        filtersSwiper.update();
        filterTag.find('svg').on('click', function () {
            const categoryId = $(this).parents('.filter-tag').attr('data-category-id');
            $(`input#category-${categoryId}`).prop("checked", false);
            $(`input#category-${categoryId}`).trigger('change');
        });
    }
    for (var i = 0; i < selectedFiltersState.preparation.length; i++) {
        const preparation = selectedFiltersState.preparation[i];
        const filterTag = $(`
            <div class="swiper-slide filter-tag" data-preparation-id="${preparation.id}">
                ${preparation.name}
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" x="0px" y="0px" viewBox="0 0 16 16" fill="#9c958b">
                    <g>
                        <path d="M8,0C3.589,0,0,3.589,0,8s3.589,8,8,8s8-3.589,8-8S12.411,0,8,0z M8,14c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6    S11.309,14,8,14z"/>
                        <polygon points="10.293,4.293 8,6.586 5.707,4.293 4.293,5.707 6.586,8 4.293,10.293 5.707,11.707 8,9.414 10.293,11.707     11.707,10.293 9.414,8 11.707,5.707   "/>
                    </g>
                </svg>
            </div>
        `);
        filtersSwiper.appendSlide(filterTag);
        filtersSwiper.update();
        filterTag.find('svg').on('click', function () {
            const preparationId = $(this).parents('.filter-tag').attr('data-preparation-id');
            $(`input#preparation-${preparationId}`).prop("checked", false);
            $(`input#preparation-${preparationId}`).trigger('change');
        });
    }
}

function loadImages() {
    $('.js-product').find('[data-src]').not('[data-loaded=true]').each(function () {
        if ($(this).is(':visible')) {
            const image = $(this);
            const offsetTop = this.getBoundingClientRect().top
            const windowHeight = $(window).height();
            if (offsetTop <= (windowHeight * 1.5)) {
                let imageUrl = image.attr('data-src');
                image.attr('data-loaded', true);
                if (image.is('img')) {
                    image.attr('src', imageUrl);
                } else {
                    image.css('background-image', `url("${imageUrl}")`);
                }
            }
        }
    });
}


export default coffeeProductsFilters;