const searchOverlay = () => {
    let scrollPosition;
    
    $('.js-open-search-overlay').on('click', function () {
        $('.js-search-overlay').fadeIn(300);
        scrollPosition = document.documentElement.scrollTop;
        $('.js-search-input-popup').focus();
        $('body').addClass('no-scroll-desktop');
        $('body').css('top', `-${scrollPosition}px`);
        
    });

    $('.js-search-overlay').on('click', function (e) {
        const target = $(e.target);
        if (!target.hasClass('js-search-overlay-content') && target.parents('.js-search-overlay-content').length === 0) {
            $('.js-search-overlay').fadeOut(300);
            $('body').removeClass('no-scroll-desktop');
            setTimeout(function() {
                $('body').css('top', 'auto');
                document.documentElement.scrollTop = scrollPosition;
            }, 10);
        }
    });

    $('.js-close-search-overlay').on('click', function () {
        $('.js-search-overlay').fadeOut(300);
        $('body').removeClass('no-scroll-desktop');
        setTimeout(function() {
            $('body').css('top', 'auto');
            document.documentElement.scrollTop = scrollPosition;
        }, 10);
    });

    let searchBtnUrl = $('.js-search-btn').attr('href');

    $('.js-search-input').on('input', function (e) {
        const inputValue = e.target.value.trim().replace(' ', '+');
        $('.js-search-btn').attr('href', `${searchBtnUrl+inputValue}`);
    });

    $('.js-search-input').on('keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            $('.js-search-btn')[0].click();
        }
    });
};

export default searchOverlay;