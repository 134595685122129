const popupWidget = function () {
    function getScrollBarWidth() {
        let el = document.createElement("div");
        el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
        document.body.appendChild(el);
        let width = el.offsetWidth - el.clientWidth;
        el.remove();
        return width;
    }

    const openPopupButton = $(".js-open-popup-widget");
    openPopupButton.on("click", function () {
        $(this).next(".js-popup-widget").fadeIn();
        $("body").css({
            "overflow-y": "hidden",
            "padding-right": `${getScrollBarWidth()}px`,
        });
    });

    $(".js-popup-widget").on('click', function() {
        $(this).fadeOut();
        setTimeout(function () {
            $("body").css({
                "overflow-y": "auto",
                "padding-right": "0",
            });
        }, 400);
    });

    $(".js-popup-widget-content").on('click', function(e) {
        e.stopPropagation();
    });
};

export default popupWidget;
