const simpleColTwoTwo = function () {

    function setBackgroundHeightAndCheckTextHeight() {
        $('.js-simple-col-2-2').each(function () {
            const widget = $(this);
            const isMobile = window.matchMedia('(max-width: 767px)').matches;
            const imgHeight = widget.find('.js-img-height').innerHeight();
            const textHeight = widget.find('.js-check-height').innerHeight();
            const bgIsCroppedTop = widget.attr('data-bg-cropped-top');
            const bgIsCroppedBottom = widget.attr('data-bg-cropped-bottom');
            const paddingBottom = parseInt(widget.css("padding-bottom"));
            const paddingTop = parseInt(widget.css("padding-top"));

            if (window.matchMedia('(min-width: 1024px)').matches) {
                if (textHeight > imgHeight - 100) {
                    widget.find('.js-first-block-desktop').css({'visibility': 'hidden', 'height': '0px'});
                    widget.find('.js-first-block-mobile').css('display', 'block');
                } else {
                    widget.find('.js-first-block-desktop').css({'visibility': 'visible', 'height': 'auto'});
                    widget.find('.js-first-block-mobile').css('display', 'none');
                }
            } else {
                widget.find('.js-first-block-mobile').css('display', 'block');
            }

            if (bgIsCroppedTop && !bgIsCroppedBottom) {
                if (isMobile) {
                    widget.find('.bg').css({
                        "height": `calc(100% - ${imgHeight / 2 + paddingTop}px)`, 
                        "top": `${imgHeight / 2 + paddingTop}px`
                    });
                } else {
                    widget.find('.bg').css({
                        "height": `calc(100% - ${paddingTop}px)`, 
                        "top": `${paddingTop}px`
                    });
                }
            } 

            if (bgIsCroppedBottom) {
                const blocksArray = widget.find('.img').parent().toArray();
                blocksArray.sort(function(a, b) {
                    return ($(b).offset().top - widget.offset().top) - ($(a).offset().top - widget.offset().top);
                });
                const lastBlockHeight = $(blocksArray[0]).outerHeight();
                let offsetHeight = lastBlockHeight - imgHeight/2 + paddingBottom;
                if (bgIsCroppedTop) {
                    if (isMobile) {
                        offsetHeight = offsetHeight + imgHeight/2 + paddingTop;
                    } else {
                        offsetHeight = offsetHeight + paddingTop;
                    }
                }
                const offsetTop = bgIsCroppedTop ?  isMobile ? imgHeight / 2 + paddingTop : paddingTop : 0; 
                widget.find('.bg').css({"height": `calc(100% - ${offsetHeight}px)`, "top": `${offsetTop}px`});
                if ($(blocksArray[1]).find('.js-img-height').length) {
                    const blockOffsetHeight = $(blocksArray[1]).outerHeight() + $(blocksArray[1]).offset().top - widget.offset().top;
                    if (widget.find('.bg').height() - 40 < blockOffsetHeight) {
                        widget.find('.bg').css('min-height', '100%');
                    } else {
                        widget.find('.bg').css('min-height', 'auto');
                    }
                } else {
                    widget.find('.bg').css('min-height', 'auto');
                }
            }
        });
    }

    setTimeout(function() {
        setBackgroundHeightAndCheckTextHeight();
    }, 100);
};

export default simpleColTwoTwo;