const accessibility = function () {
    $(window).on("keyup", function(e) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 9) {
            $(e.target).addClass('tab-focus');
        }
        if ($(e.target).is('input[type=radio]') || $(e.target).is('input[type=checkbox]')) {
            if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
                $(e.target).addClass('tab-focus');
            }
        }
        if (keyCode === 9 && $(e.target).is('input[type=file]')) {
            $(e.target).parents('.upload-input').find('.js-upload-button').addClass('tab-focus');
        }
    });

    $(window).on("keydown", function(e) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 9) {
            $(e.target).removeClass('tab-focus');
        }
        if ($(e.target).is('input[type=radio]') || $(e.target).is('input[type=checkbox]')) {
            if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
                $(e.target).removeClass('tab-focus');
            }
        }
        if (keyCode === 9 && $(e.target).is('input[type=file]')) {
            $(e.target).parents('.upload-input').find('.js-upload-button').removeClass('tab-focus');
        }
    });

    $(window).on("keypress", function(e) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13
            && $(e.target).parents('form').length
            && (
                $(e.target).is('input[type=radio]') 
                || $(e.target).is('input[type=checkbox]') 
                || $(e.target).is('input[type=text]')
                )
            ) {
            e.preventDefault();
        }
    });

    $('body').on('click', function(e) {
        if (!$(e.target).is('input[type=radio]') && !$(e.target).is('input[type=checkbox]')) {
            $('.tab-focus').removeClass('tab-focus');
        }
    });
};

export default accessibility;