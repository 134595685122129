import { UploadInput } from "../inputs/upload/upload";
import { Form } from "../form/form";

const contactForm = function() {
    if ($('#ComplaintFiles').length && $('#contactForm').length) {
        const uploadInput = new UploadInput('ComplaintFiles');
        uploadInput.init();
    
        const contactForm = new Form(
            '#contactForm', 
            '#contactFormSuccessMessage', 
            '#contactFormErrorMessage',
            [
                {
                    uploadInput: uploadInput,
                    uploadInputName: uploadInput.inputName
                },
            ],
            {
                City: "",
                ComplaintFiles: [],
                Country: "",
                Email: "",
                Message: "",
                Name: "",
                Phone: "",
                ProductName: "",
                ReceiveInformation: false,
                Region: "",
                SubjectPrimary: "",
                SubjectPrimaryId: "",
                SubjectSecondary: ""
            }
        );
        contactForm.init();

        contactForm.formData.Country = $('#country').attr('data-option-id');

        $('#country').on('change', function (e) {
            const countryId = $(this).attr('data-option-id');
            contactForm.formData.Country = countryId;
            $('[data-country-id]').hide();
            $(`[data-country-id=${countryId}]`).show();
        })
    } 
};

export default contactForm;