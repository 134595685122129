import Swiper from "swiper/dist/js/swiper.min";

const imageSlider = function () {
    $(".image-slider .swiper-container").each(function () {
        const swiperContainer = $(this);
        const imageSwiper = new Swiper(swiperContainer, {
            preloadImages: false,
            speed: 600,
            init: false,
            slidesPerView: "auto",
            spaceBetween: 32,
            a11y: true,
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
                firstSlideMessage: "This is the first slide",
                lastSlideMessage: "This is the last slide",
                paginationBulletMessage: "Go to slide {{index}}",
            },
            scrollbar: {
                el: swiperContainer.parents('.image-slider').find(".swiper-scrollbar")
            },
            navigation: {
                nextEl: swiperContainer.parents('.image-slider').find(".swiper-button-next"),
                prevEl: swiperContainer.parents('.image-slider').find(".swiper-button-prev"),
            },
            on: {
                slideChange: function () {
                    swiperContainer.find('.swiper-slide').each(function () {
                        if ($(this).index() < imageSwiper.activeIndex) {
                            $(this).addClass('swiper-slide--hidden');
                        } else {
                            $(this).removeClass('swiper-slide--hidden');
                        }
                    })
                },
                resize: function () {
                    const swiper = swiperContainer[0].swiper;
                    if (swiper.isBeginning && swiper.isEnd) {
                        swiper.allowTouchMove = false;
                        swiperContainer.find('.swiper-wrapper').addClass('justify-center');
                        swiperContainer.parents('.image-slider').find('.image-slider__next-wrapper').hide();
                        swiperContainer.parents('.image-slider').find('.image-slider__prev-wrapper').hide();
                    } else {
                        swiper.allowTouchMove = true;
                        swiperContainer.find('.swiper-wrapper').removeClass('justify-center');
                        swiperContainer.parents('.image-slider').find('.image-slider__next-wrapper').show();
                        swiperContainer.parents('.image-slider').find('.image-slider__prev-wrapper').show();
                    }
                },

            }
        });
        imageSwiper.on('init', function () {
            const swiper = swiperContainer[0].swiper;
            if (swiper.isBeginning && swiper.isEnd) {
                swiper.allowTouchMove = false;
                swiperContainer.find('.swiper-wrapper').addClass('justify-center');
            } else {
                swiperContainer.parents('.image-slider').find('.image-slider__next-wrapper').show();
                swiperContainer.parents('.image-slider').find('.image-slider__prev-wrapper').show();
            }
        });
        imageSwiper.init();

    });

};

export default imageSlider;
