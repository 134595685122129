const threeRedBox = function() {
    function setBackgroundHeight() {
        $('.js-3-red-box').each(function () {
            const isDesktop = window.matchMedia('(min-width: 1280px)').matches;
            const widget = $(this);
            const imgHeight = widget.find('.js-check-img-height').innerHeight();
            const squareHeight = widget.find('.js-check-square-height').innerHeight();
            const squareMarginTop = parseInt(widget.find('.js-check-square-height').css('margin-top'));
            const marginTop = imgHeight - (squareHeight + squareMarginTop);
            if (isDesktop) {
                widget.find('.js-calculate-margin-top').css('margin-top', `-${marginTop}px`);
                const bgHeight = squareHeight * 2 - 40 - 80 * 2;
                widget.find('.bg').css('height', `${bgHeight}px`);
            } else {
                widget.find('.js-calculate-margin-top').css('margin-top', '0px');
                const bgHeight = widget.find('.js-check-square-height').height() / 2 + parseInt(widget.css('padding-top')) + widget.find('.js-check-square-height')[0].offsetTop;
                widget.find('.bg').css('height', `${bgHeight}px`);
            }
        });
    }

    setTimeout(function() {
        setBackgroundHeight();
    }, 100);
};

export default threeRedBox;