const calculateSplitHeaderPaddingBottom = function () {

    $('.js-split-header').each(function () {
        const header = $(this);
        if ($('.js-split-header-links').length > 0) {
            header.css('padding-bottom', '16px');
        } else {
            const paddingBottom = header.find('.img--1x1').innerHeight() - header.height();
            if (window.matchMedia('(max-width: 1023px)').matches) {
                header.css('padding-bottom', '0');
            } else {
                header.css('padding-bottom', `${16 + paddingBottom}px`);
            }
        }
    });
};

export default calculateSplitHeaderPaddingBottom;