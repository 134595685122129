const dropdown = () => {
    $(".dropdown__selected-option").on('click', function (e) {
        e.stopPropagation();
        const dropdown = $(this).parent();
        dropdown.toggleClass("open");
        if (dropdown.hasClass("dropdown--links"))
            dropdown.find(".dropdown__option").removeClass("selected");
        const selectedOption = dropdown.find(".dropdown__option.selected");
        if (
            selectedOption.length &&
            !dropdown.hasClass("dropdown--lang") &&
            !dropdown.hasClass("dropdown--lang-footer")
        ) {
            const offsetTop = selectedOption[0].offsetTop - 6;
            dropdown.find(".dropdown__menu-wrapper").scrollTop(offsetTop);
        }
    });

    $(".dropdown-filter__title").on('click', function (e) {
        e.stopPropagation();
        const dropdown = $(this).parent();
        $(".dropdown-filter").each(function () {
            if (!$(this).is(dropdown)) {
                $(this).removeClass("open");
            }
        });
        dropdown.toggleClass("open");
    });

    $("body").on('click', function (e) {
        const target = $(e.target);
        if (
            !target.is(".dropdown__menu") &&
            !target.parents(".dropdown__menu").length
        ) {
            $(".dropdown.open").removeClass("open");
        }
        if (
            !target.is(".dropdown-filter__menu") &&
            !target.parents(".dropdown-filter__menu").length
        ) {
            $(".dropdown-filter.open").removeClass("open");
        }
    });

    function setSelectedOption(option) {
        const optionValue = option.attr("data-option");
        const optionId = option.attr("id");
        const optionText = option.html();
        option.addClass("selected").siblings().removeClass("selected");
        if (!option.parents(".dropdown").find("input").length) {
            return;
        }
        if (
            option.parents(".dropdown").hasClass("dropdown--lang") ||
            option.parents(".dropdown").hasClass("dropdown--lang-footer")
        ) {
            if (optionValue === "int") {
                option
                    .parents(".dropdown")
                    .find(".dropdown__selected-option")
                    .html(option.find("svg").clone())
                    .attr("data-selected", true);
            } else {
                option
                    .parents(".dropdown")
                    .find(".dropdown__selected-option")
                    .html(optionValue)
                    .attr("data-selected", true);
            }
        }
        else if (option.parents(".dropdown").hasClass("dropdown--venue")) {
            option
                .parents(".dropdown")
                .find(".dropdown__selected-option")
                .html(optionText)
                .attr("data-selected", true);

            if (optionValue) {
                const params = new URLSearchParams(window.location.search);
                if (params.get('vid') !== optionValue) {
                    params.set('vid', optionValue);
                    const newUrl = window.location.origin
                        + window.location.pathname
                        + '?' + params.toString();
                    window.location.replace(newUrl)
                }
            }
        }
        else {
            option
                .parents(".dropdown")
                .find(".dropdown__selected-option")
                .html(optionText)
                .attr("data-selected", true);
        }
        option.parents(".dropdown").find("input").val(optionValue);
        if (optionId) {
            option
                .parents(".dropdown")
                .find("input")
                .attr("data-option-id", optionId);
        }
        option.parents(".dropdown").find("input").trigger("change");
    }

    $(".dropdown__option").on('click', function () {
        const option = $(this);
        if (!option.parents(".dropdown").find("input").length) return;
        setSelectedOption(option);
        option.parents(".dropdown").removeClass("open");
    });

    $(".dropdown__selected-option").on("keydown", function (e) {
        const dropdown = $(e.target).parents(".dropdown");
        const keyCode = e.keyCode || e.which;
        const selectedOption = $(e.target)
            .parents(".dropdown")
            .find(".dropdown__option:not(.dropdown__option--no-link).selected");
        const firstOption = $(
            $(e.target)
                .parents(".dropdown")
                .find(".dropdown__option")
                .not(".dropdown__option--no-link")[0]
        );
        const dropdownMenuHeight = dropdown.find(".dropdown__menu").height();
        const menuScrollTop = dropdown
            .find(".dropdown__menu-wrapper")
            .scrollTop();

        // Enter
        if (keyCode === 13) {
            if (
                dropdown.hasClass("open") &&
                dropdown.hasClass("dropdown--links") &&
                selectedOption.length
            ) {
                selectedOption[0].click();
            }
            if (!dropdown.hasClass("open") && selectedOption.length) {
                const offsetTop = selectedOption[0].offsetTop - 6;
                dropdown.find(".dropdown__menu-wrapper").scrollTop(offsetTop);
            }
            dropdown.toggleClass("open");
        }

        // Tab
        if (keyCode === 9 && dropdown.hasClass("open")) {
            dropdown.removeClass("open");
            if (dropdown.hasClass("dropdown--links") && selectedOption.length) {
                selectedOption.removeClass("selected");
            }
        }

        // ArrowDown
        if (keyCode === 40) {
            e.preventDefault();
            const option =
                selectedOption.length > 0
                    ? selectedOption.next(
                        ".dropdown__option:not(.dropdown__option--no-link)"
                    )
                    : firstOption;
            if (option.length) {
                setSelectedOption(option);
                const offsetTop =
                    option[0].offsetTop -
                    6 +
                    option.innerHeight() -
                    dropdownMenuHeight;
                if (
                    offsetTop > menuScrollTop ||
                    menuScrollTop - offsetTop >= dropdownMenuHeight
                ) {
                    dropdown
                        .find(".dropdown__menu-wrapper")
                        .scrollTop(offsetTop);
                }
            }
        }

        // ArrowUp
        if (keyCode === 38) {
            e.preventDefault();
            const option =
                selectedOption.length > 0
                    ? selectedOption.prev(
                        ".dropdown__option:not(.dropdown__option--no-link)"
                    )
                    : firstOption;
            if (option.length) {
                setSelectedOption(option);
                const offsetTop = option[0].offsetTop - 6;
                if (
                    offsetTop <= menuScrollTop - option.innerHeight() ||
                    offsetTop - menuScrollTop >= dropdownMenuHeight
                ) {
                    dropdown
                        .find(".dropdown__menu-wrapper")
                        .scrollTop(offsetTop);
                }
            }
        }
    });
};

export default dropdown;
