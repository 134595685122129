import Swiper from "swiper/dist/js/swiper.min";

function load(image) {
    const isSmallScreen = window.matchMedia('(max-width: 480px)').matches;
    const isMediumScreen = window.matchMedia('(min-width: 481px) and (max-width: 1023px)').matches;
    const isLargeScreen = window.matchMedia('(min-width: 1024px)').matches;
    let imageUrl = image.attr('data-bg');
    const isGif = imageUrl.indexOf('.gif') !== -1;
    const offsetTop = image[0].getBoundingClientRect().top
    const windowHeight = $(window).height();
    if (offsetTop <= (windowHeight * 1.5)) {
        if (isSmallScreen && image.attr('data-size-sm') && image.attr('data-loaded') !== 'sm' && !isGif) {
            if (image.attr('data-size-sm') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-sm')}`;
                image.attr('data-loaded', 'sm');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isMediumScreen && image.attr('data-size-md') && image.attr('data-loaded') !== 'md' && !isGif) {
            if (image.attr('data-size-md') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-md')}`;
                image.attr('data-loaded', 'md');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isLargeScreen && image.attr('data-size-lg') && image.attr('data-loaded') !== 'lg' && !isGif) {
            if (image.attr('data-size-lg') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-lg')}`;
                image.attr('data-loaded', 'lg');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (!image.attr('data-loaded')) {
            image.attr('data-loaded', true);
            image.css('background-image', `url("${imageUrl}")`);
        }
    }
}

function zoomIn(image) {
    image.addClass('zoom-in');
}

function zoomOut(image) {
    image.removeClass('zoom-in');
}

export function lazyLoadHeroSlider() {
    $(".hero-slider .swiper-container").each(function () {
        const swiperContainer = $(this);
        const offsetTop = swiperContainer[0].getBoundingClientRect().top
        const windowHeight = $(window).height();
        if (offsetTop <= windowHeight * 1.5 && !swiperContainer.attr('data-autoplay-started')) {
            if (swiperContainer.find('.swiper-slide').length > 1) {
                swiperContainer[0].swiper.autoplay.start();
                swiperContainer.attr('data-autoplay-started', true);
            } else {
                const image = swiperContainer.find('.swiper-slide .img');
                load(image);
            }
        }
    });
}

export function heroSlider() {
    $(".hero-slider .swiper-container").each(function () {
        const swiperContainer = $(this);
        const numberOfSlides = swiperContainer.find('.swiper-slide').length;
        if (swiperContainer.find('.swiper-slide').length > 1) {
            const heroSwiper = new Swiper(swiperContainer, {
                speed: 800,
                loop: true,
                slidesPerView: "auto",
                a11y: true,
                a11y: {
                    prevSlideMessage: "Previous slide",
                    nextSlideMessage: "Next slide",
                    firstSlideMessage: "This is the first slide",
                    lastSlideMessage: "This is the last slide",
                    paginationBulletMessage: "Go to slide {{index}}",
                },
                pagination: {
                    el: swiperContainer.parents('.hero-slider').find(".swiper-pagination"),
                },
                navigation: {
                    nextEl: swiperContainer.parents('.hero-slider').find(".swiper-button-next"),
                    prevEl: swiperContainer.parents('.hero-slider').find(".swiper-button-prev"),
                },
                on: {
                    slideNextTransitionStart: function () {
                        if (heroSwiper) {
                            swiperContainer.find('.swiper-slide').each(function () {
                                const image = $(this).find('.img');
                                const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                                const activeSlideIndex = heroSwiper.realIndex;
                                let nextSlideIndex = activeSlideIndex === numberOfSlides - 1 ? 0 : activeSlideIndex + 1;
                                if (slideIndex === nextSlideIndex) {
                                    load(image);
                                }
                            });
                        }
                    },
                    slidePrevTransitionStart: function () {
                        if (heroSwiper) {
                            swiperContainer.find('.swiper-slide').each(function () {
                                const image = $(this).find('.img');
                                const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                                const activeSlideIndex = heroSwiper.realIndex;
                                let prevSlideIndex = activeSlideIndex === 0 ? numberOfSlides - 1 : activeSlideIndex - 1;
                                if (slideIndex === prevSlideIndex) {
                                    load(image);
                                }
                            });
                        }
                    },
                    autoplayStart: function () {
                        swiperContainer.find('.swiper-slide').each(function (i) {
                            const image = $(this).find('.img');
                            const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                            const activeSlideIndex = heroSwiper.realIndex;
                            const lastSlideIndex = numberOfSlides - 1;
                            let nextSlideIndex = activeSlideIndex === numberOfSlides - 1 ? 0 : activeSlideIndex + 1;
                            if (slideIndex === activeSlideIndex || slideIndex === nextSlideIndex || slideIndex === lastSlideIndex) {
                                load(image);
                            }
                            if (slideIndex === activeSlideIndex) {
                                zoomIn(image);
                            }
                        });
                    },
                    slideChangeTransitionEnd: function () {
                        if (heroSwiper) {
                            swiperContainer.find('.swiper-slide').each(function () {
                                const image = $(this).find('.img');
                                const activeSlideIndex = heroSwiper.realIndex;
                                const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                                if (slideIndex === activeSlideIndex) {
                                    zoomIn(image);
                                } else {
                                    zoomOut(image);
                                }
                            });
                        }
                    },
                    resize: function () {
                        swiperContainer.find('.swiper-slide').each(function (i) {
                            const image = $(this).find('.img');
                            const slideIndex = parseInt($(this).attr('data-swiper-slide-index'));
                            const activeSlideIndex = heroSwiper.realIndex;
                            let nextSlideIndex = activeSlideIndex === numberOfSlides - 1 ? 0 : activeSlideIndex + 1;
                            if (slideIndex === activeSlideIndex || slideIndex === nextSlideIndex) {
                                load(image);
                            }
                        });
                    }
                }
            });

            swiperContainer.parents('.hero-slider').find('.hero-slider__next-wrapper').fadeIn(300);
            swiperContainer.parents('.hero-slider').find('.hero-slider__prev-wrapper').fadeIn(300);

            swiperContainer.find('.js-open-video-overlay').on('click', function () {
                heroSwiper.autoplay.stop();
            });
        } else {
            swiperContainer.css('width', '100%');
        }
    })
    lazyLoadHeroSlider();
};
