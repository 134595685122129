const videoWidget = function () {
    $('[data-video-src]').each(function () {
        const offsetTop = $(this).parents('.js-video-widget')[0].getBoundingClientRect().top;
        const windowHeight = $(window).height();
        if (offsetTop <= (windowHeight * 1.5) && !$(this).attr('data-loaded')) {
            const isYoutube = $(this).is('iframe');
            const videoUrl = $(this).attr('data-video-src');
            if (isYoutube) {
                let videoId = videoUrl.split('v=')[1];
                if (typeof(videoId) !== "undefined"){
                    var ampersandPosition = videoId.indexOf('&');
                    if(ampersandPosition != -1) {
                        videoId = videoId.substring(0, ampersandPosition);
                    }
                    $(this).attr('src', `https://www.youtube.com/embed/${videoId}`);
                }
                else{
                    if (videoUrl.includes("/embed/")){
                        $(this).attr('src', videoUrl);
                    }
                }
            } else {
                const videoTag = $(this).parents('video');
                videoTag.find('source').attr('src', videoUrl);
                videoTag[0].load();
            }
            $(this).attr('data-loaded', true);
        }
    });
};

export default videoWidget;