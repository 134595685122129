var FontFaceOnload = require('FontFaceOnload');
const debounce = require("debounce");

const background = function () {
    function setBackgroundHeight() {
        $(".js-calculate-bg-height").each(function () {
            const bg = $(this).find(".bg");
            const croppedPosition = $(this).attr("data-cropped");
            const imgPosition = $(this).attr("data-img-position");
            let imgToCheck = $(this).find(".js-check-img-height").length
                ? $(this).find(".js-check-img-height")
                : $($(this).find(".img")[0]);
            if (imgToCheck.length > 0) {
                if (imgToCheck.hasClass("js-animate-offset")) {
                    imgToCheck = imgToCheck.parent();
                }
                let imageOffset =
                    imgToCheck[0].getBoundingClientRect().top -
                    this.getBoundingClientRect().top -
                    parseInt($(this).css("padding-top"));
                if (croppedPosition === "bottom") {
                    imgToCheck = $(
                        $(this).find(".img")[$(this).find(".img").length - 1]
                    );
                    imageOffset =
                        $(this).innerHeight() -
                        (imgToCheck[0].getBoundingClientRect().top -
                            this.getBoundingClientRect().top +
                            imgToCheck.innerHeight()) -
                        parseInt($(this).css("padding-bottom"));
                }
                const imgHeight = imgToCheck.innerHeight();
                if (imgPosition === "bottom") {
                    bg.css(
                        "height",
                        `${
                            imgHeight / 2 + parseInt($(this).css("padding-top"))
                        }px`
                    ).removeClass("bg--hidden");
                } else {
                    if (croppedPosition === "bottom") {
                        bg.css(
                            "height",
                            `calc(100% - ${
                                imgHeight / 2 +
                                parseInt($(this).css("padding-top")) +
                                imageOffset
                            }px`
                        ).removeClass("bg--hidden");
                    } else {
                        bg.css(
                            "height",
                            `calc(100% - ${
                                imgHeight / 2 +
                                parseInt($(this).css("padding-bottom")) +
                                imageOffset
                            }px`
                        ).removeClass("bg--hidden");
                    }
                }
            } else {
                bg.addClass("min-h-full");
            }
        });

        const isMobile = window.matchMedia("(max-width: 1023px)").matches;
        const isMobile2xs = window.matchMedia("(max-width: 639px)").matches;
        $(".js-calculate-bg-height-header").each(function () {
            const bg = $(this).find(".js-bg");
            const imgHeight = $($(this).find(".img--16x9")[0]).innerHeight();
            if (isMobile) {
                bg.css(
                    "height",
                    `calc(100% + ${imgHeight - (isMobile2xs ? 24 : 40)}px`
                );
            } else {
                bg.css("height", "100%");
            }
        });

        $(".js-calculate-bg-height-contact-header").each(function () {
            const bg = $(this).find(".js-bg");
            const imgHeight = $(".js-img-contact-header").innerHeight();
            if (isMobile) {
                bg.css("height", `calc(100% + ${imgHeight / 2}px`).removeClass(
                    "bg--hidden"
                );
            } else {
                bg.css("height", "100%");
            }
        });
    }

    setTimeout(function () {
        setBackgroundHeight();
    }, 100);

    const isDesktop = window.matchMedia("(min-width: 1024px)").matches;
    if ($(".js-tea-expertise-header").length && isDesktop) {
        const womanImageHeight = setInterval(setWomanImageHeight, 100);
        function setWomanImageHeight() {
            if ($(".js-woman-img").height() > 0) {
                $(".js-tea-expertise-header").css(
                    "min-height",
                    `${$(".js-woman-img").height()}px`
                );
                clearInterval(womanImageHeight);
            }
        }
    }
    if ($(".js-tea-expertise-header").length && !isDesktop) {
        $(".js-tea-expertise-header").css("min-height", "0");
    }

    function startsWith(str, word) {
        return str.lastIndexOf(word, 0) === 0;
    }

    const getFontSizeForBreakpoint = function (classNames, breakpoint) {
        let fontSize; 
        let cssClass = 'text-';
        if (breakpoint === 'lg') cssClass = 'lg:text-';
        if (breakpoint === 'md') cssClass = 'md:text-';
        if (breakpoint === 'sm') cssClass = 'sm:text-';
        if (breakpoint === 'xs') cssClass = 'xs:text-';
        if (breakpoint === '2xs') cssClass = '2xs:text-';
        
        classNames.split(' ').map(function (className) {
            if (startsWith(className, cssClass) && parseInt(className.split('-')[1])) {
                fontSize = parseInt(className.split('-')[1])
            }
        })
        return fontSize;
    }

    const fitText = function () {
        const box = $('.img--1x1[class*="bg--red"]');
        box.each(function () {
            if ($(this).is(":visible") == false) return;
            let text = $(this).find("h2");
            let textBox = text;
            if ($(this).find("div > div").length) textBox = $(this).find("div > div");
            const className = text[0].className;
            let font = getFontSizeForBreakpoint(className);
            if (window.matchMedia('(max-width: 1439px)').matches) {
                font = getFontSizeForBreakpoint(className, 'lg') || font;
            }
            if (window.matchMedia('(max-width: 1279px)').matches) {
                font = getFontSizeForBreakpoint(className, 'md') || font;
            }
            if (window.matchMedia('(max-width: 1023px)').matches) {
                font = getFontSizeForBreakpoint(className, 'sm') || font;
            }
            if (window.matchMedia('(max-width: 767px)').matches) {
                font = getFontSizeForBreakpoint(className, 'xs') || font;
            }
            if (window.matchMedia('(max-width: 639px)').matches) {
                font = getFontSizeForBreakpoint(className, '2xs') || font;
            }
            $(this).find("h2").css("font-size", `${font}px`);
            while (
                parseFloat(textBox.width().toFixed(2)) > parseFloat($(this).find('.absolute.h-full.w-full').width().toFixed(2)) || 
                parseFloat(textBox.height().toFixed(2)) > parseFloat($(this).find('.absolute.h-full.w-full').height().toFixed(2))) 
            {
                font -= 1;
                if (font === 14) {
                    $(this).find("h2").css("font-size", `14.5px`);
                } else {
                    $(this).find("h2").css("font-size", `${font}px`);
                }
                
            }
        });
    }

    let font = 'Pluto-Bold';
    if ($('html').hasClass('fontface-ru')) font = 'ProstoSans-Bold';
    if ($('html').hasClass('fontface-cn')) font = 'AmasisMTPro-Bold';
    FontFaceOnload(font, {
        success: function() {
            fitText();
        }
    });

    $(window).on('resize', debounce(function () {
        fitText();
    }, 50));
};

export default background;
