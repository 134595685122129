'use strict';

import jQuery from 'jquery';
window.$ = jQuery;
require('es6-promise').polyfill();
import "swiper/dist/css/swiper.css";
import './styles.scss';
import functionsInit from './functions';

class App {
    initializeApp() {
        $(() => {
            functionsInit();
        });
    };
}

const app = new App();
app.initializeApp();
