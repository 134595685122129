import Swiper from "swiper/dist/js/swiper.min";

function load(image) {
    const isSmallScreen = window.matchMedia('(max-width: 480px)').matches;
    const isMediumScreen = window.matchMedia('(min-width: 481px) and (max-width: 1023px)').matches;
    const isLargeScreen = window.matchMedia('(min-width: 1024px)').matches;
    let imageUrl = image.attr('data-bg');
    const isGif = imageUrl.indexOf('.gif') !== -1;
    const offsetTop = image[0].getBoundingClientRect().top
    const windowHeight = $(window).height();
    if (offsetTop <= (windowHeight * 1.5)) {
        if (isSmallScreen && image.attr('data-size-sm') && image.attr('data-loaded') !== 'sm' && !isGif) {
            if (image.attr('data-size-sm') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-sm')}`;
                image.attr('data-loaded', 'sm');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isMediumScreen && image.attr('data-size-md') && image.attr('data-loaded') !== 'md' && !isGif) {
            if (image.attr('data-size-md') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-md')}`;
                image.attr('data-loaded', 'md');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isLargeScreen && image.attr('data-size-lg') && image.attr('data-loaded') !== 'lg' && !isGif) {
            if (image.attr('data-size-lg') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-lg')}`;
                image.attr('data-loaded', 'lg');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (!image.attr('data-loaded')) {
            image.attr('data-loaded', true);
            image.css('background-image', `url("${imageUrl}")`);
        }
    }
}

const tabsWidget = function () {
    $(".tabs-widget .swiper-container").each(function () {
        const swiperContainer = $(this);
        const offsetTop = swiperContainer[0].getBoundingClientRect().top
        const windowHeight = $(window).height();
        if (offsetTop <= windowHeight * 1.5 && !swiperContainer.hasClass('swiper-container-initialized') && swiperContainer.parent().attr('data-tab-active') === 'true') {
            initSwiper(swiperContainer);
        }
    });

    $('.tab-link').on('click', function () {
        const tabLinkIndex = $(this).index();
        $(this).addClass('tab-link--active').siblings().removeClass('tab-link--active');
        const activeTab = $($(this).parents('.tabs-widget').find('.tabs-widget__tab')[tabLinkIndex]);
        activeTab.show().attr('data-tab-active', 'true');
        activeTab.siblings().hide().attr('data-tab-active', 'false');
        const swiperContainer = activeTab.find('.swiper-container');
        if (!swiperContainer.hasClass('swiper-container-initialized')) {
            initSwiper(swiperContainer);
        }
    });

    function initSwiper(swiperContainer) {
        const tabsSwiper = new Swiper(swiperContainer, {
            preloadImages: false,
            autoHeight: true,
            init: false,
            speed: 600,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            slidesPerView: 1,
            spaceBetween: 32,
            a11y: true,
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
                firstSlideMessage: "This is the first slide",
                lastSlideMessage: "This is the last slide",
                paginationBulletMessage: "Go to slide {{index}}",
            },
            pagination: {
                el: swiperContainer.find(".swiper-pagination"),
            },
            navigation: {
                nextEl: swiperContainer.find(".tabs-widget__nav--next"),
                prevEl: swiperContainer.find(".tabs-widget__nav--prev"),
            },
            on: {
                slideNextTransitionStart: function () {
                    if (tabsSwiper) {
                        swiperContainer.find('.swiper-slide').each(function (i) {
                            const activeSlideIndex = tabsSwiper.activeIndex;
                            let nextSlideIndex = activeSlideIndex === swiperContainer.find('.swiper-slide').length - 1 ? null : activeSlideIndex + 1;
                            if (i === nextSlideIndex) {
                                $(this).find('[data-bg]').each(function () {
                                    const img = $(this);
                                    load(img);
                                });
                            }
                        });
                    }
                },
                slidePrevTransitionStart: function () {
                    if (tabsSwiper) {
                        swiperContainer.find('.swiper-slide').each(function (i) {
                            const activeSlideIndex = tabsSwiper.activeIndex;
                            let prevSlideIndex = activeSlideIndex === 0 ? null : activeSlideIndex - 1;
                            if (i === prevSlideIndex) {
                                $(this).find('[data-bg]').each(function () {
                                    const img = $(this);
                                    load(img);
                                });
                            }
                        });
                    }
                },
                resize: function () {
                    $(swiperContainer.find('.swiper-slide')[tabsSwiper.activeIndex]).find('[data-bg]').each(function () {
                        const img = $(this);
                        load(img);
                    });
                    if (tabsSwiper.activeIndex !== swiperContainer.find('.swiper-slide').length - 1) {
                        $(swiperContainer.find('.swiper-slide')[tabsSwiper.activeIndex + 1]).find('[data-bg]').each(function () {
                            const img = $(this);
                            load(img);
                        });
                    }
                    if (tabsSwiper.activeIndex !== 0) {
                        $(swiperContainer.find('.swiper-slide')[tabsSwiper.activeIndex - 1]).find('[data-bg]').each(function () {
                            const img = $(this);
                            load(img);
                        });
                    }
                }

            }
        });
        tabsSwiper.on('init', function () {
            $(swiperContainer.find('.swiper-slide')[tabsSwiper.activeIndex]).find('[data-bg]').each(function () {
                const img = $(this);
                load(img);
            });
            $(swiperContainer.find('.swiper-slide')[tabsSwiper.activeIndex + 1]).find('[data-bg]').each(function () {
                const img = $(this);
                load(img);
            });
        });
        tabsSwiper.init();
    }
};

const calculateTabsWidgetBgWidth = function () {
    $(".tabs-widget").each(function () {
        const bgDesktop = $(this).find('.js-bg-desktop');
        const bgWidth = $(window).width() - $('.quote').parent().innerWidth() - (($(window).width() - $(this).find('.container').width()) / 2);
        bgDesktop.css('width', `${bgWidth}px`);

        const bgMobile = $(this).find('.js-bg-mobile');
        bgMobile.each(function () {
            const bg = $(this);
            const textHeight = bg.parents('.swiper-slide').find('.js-check-height').innerHeight();
            const imgHeight = bg.parents('.swiper-slide').find('.js-check-img-height').innerHeight();
            bg.css('height', `${textHeight + imgHeight / 2}px`);
        });
    });
};

export { tabsWidget, calculateTabsWidgetBgWidth };
