const socialMediaShare = function () {
    const facebookShare = $(".js-fb-share");
    const twitterShare = $(".js-twitter-share");
    const linkedinShare = $(".js-linkedin-share");
    const pinterestShare = $(".js-pinterest-share");
    const linkShare = $(".js-link-share");
    let url = window.location.href;

    const popUpWindow = function (url, width, height) {
        const dualScreenLeft = window.screenLeft;
        const dualScreenTop = window.screenTop;
        const windowWidth = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth;
        const windowHeight = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight;
        const leftPosition = windowWidth / 2 - width / 2 + dualScreenLeft;
        const topPosition = windowHeight / 2 - height / 2 + dualScreenTop;
        const params = `height=${height},width=${width},left=${leftPosition},top=${topPosition},scrollbars=yes,resizable=yes`;
        window.open(url, "Share", params);
    };

    facebookShare.on("click", function (e) {
        console.log("here");
        e.preventDefault();
        if (facebookShare.attr("data-url"))
            url = facebookShare.attr("data-url");
        console.log(url);
        const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${url}`;
        popUpWindow(facebookShareUrl, "600", "600");
    });

    twitterShare.on("click", function (e) {
        e.preventDefault();
        if (twitterShare.attr("data-url")) url = twitterShare.attr("data-url");
        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}`;
        popUpWindow(twitterShareUrl, "600", "600");
    });

    linkedinShare.on("click", function (e) {
        e.preventDefault();
        if (linkedinShare.attr("data-url"))
            url = linkedinShare.attr("data-url");
        const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${url}`;
        popUpWindow(linkedinShareUrl, "600", "600");
    });

    pinterestShare.on("click", function (e) {
        e.preventDefault();
        if (pinterestShare.attr("data-url"))
            url = pinterestShare.attr("data-url");
        const media = $(".js-share-media")[0].src;
        const linkedinShareUrl = `https://www.pinterest.com/pin/create/button/?url=${url}&media=${media}`;
        popUpWindow(linkedinShareUrl, "600", "600");
    });

    linkShare.on("click", function (e) {
        const $this = $(this);
        const el = document.createElement("textarea");
        el.value = url;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(el);
        $this.find("span").fadeIn();
        setTimeout(function () {
            $this.find("span").fadeOut();
        }, 1000);
    });
};

export default socialMediaShare;
