const axios = require('axios').default;

export function handleSearchLoadMore() {
    const loadMoreEl = document.querySelector('.js-search-load-more');

    if (loadMoreEl) {
        loadMoreEl.addEventListener('click', (e) => {
            const { target } = e;
            const { dataset } = target;
            const { href } = dataset;

            if (href) {
                axios.get(href)
                    .then(function (response) {
                        const { data, status } = response;
                        if (status == 200 && data) {
                            // Remove current button, new one is returned from BE with the refresh data
                            document.getElementById('load-more-searchresults-ajax-placeholder').remove();
                            // append data
                            document.getElementById('more-results-listing-ajax-placeholder').innerHTML += data;
                            handleSearchLoadMore();
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        });
    }
}