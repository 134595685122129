import Swiper from "swiper/dist/js/swiper.min";

const productSlider = function () {
    $(".product-slider .swiper-container").each(function () {
        const swiperContainer = $(this);
        const productSwiper = new Swiper(swiperContainer, {
            preloadImages: false,
            speed: 600,
            slidesPerView: "auto",
            spaceBetween: 32,
            a11y: true,
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
                firstSlideMessage: "This is the first slide",
                lastSlideMessage: "This is the last slide",
                paginationBulletMessage: "Go to slide {{index}}",
            },
            scrollbar: {
                el: swiperContainer.parents('.product-slider').find(".swiper-scrollbar")
            },
            on: {
                slideChange: function () {

                    swiperContainer.find('.swiper-slide').each(function () {
                        if ($(this).index() < productSwiper.activeIndex) {
                            $(this).addClass('swiper-slide--hidden');
                        } else {
                            $(this).removeClass('swiper-slide--hidden');
                        }
                    })
                },
            }
        });

    });

    $(".product-detail-slider").each(function() {
        const swiper = $(this)
        const swiperContainer = $(this).find('.swiper-container');
        const slidesCount = swiperContainer.find('.swiper-slide').length;
        if (slidesCount === 1) return
        const productDetailSwiper = new Swiper(swiperContainer, {
            preloadImages: false,
            speed: 600,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            slidesPerView: 1,
            spaceBetween: 32,
            a11y: true,
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
                firstSlideMessage: "This is the first slide",
                lastSlideMessage: "This is the last slide",
                paginationBulletMessage: "Go to slide {{index}}",
            },
            pagination: {
                el: swiper.find(".swiper-pagination"),
                clickable: true
            },
            navigation: {
                nextEl: swiper.find(".swiper-button-next"),
                prevEl: swiper.find(".swiper-button-prev"),
            },
        });
        swiper.find(".swiper-button-next").removeClass('hidden')
        swiper.find(".swiper-button-prev").removeClass('hidden')

    });

};

export default productSlider;
