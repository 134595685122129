const videoOverlay = () => {
    let scrollPosition;

    $('.js-open-video-overlay').on('click', function () {
        const videoUrl = $(this).attr('data-video-url');
        const isYoutube = $(this).attr('data-is-youtube') === "true";
        if (isYoutube) {
            let videoId = videoUrl.split('v=')[1];
            if (typeof(videoId) !== "undefined"){
                var ampersandPosition = videoId.indexOf('&');
                if(ampersandPosition != -1) {
                    videoId = videoId.substring(0, ampersandPosition);
                }
                $('.js-video-overlay').find('iframe').attr('src', `https://www.youtube.com/embed/${videoId}?autoplay=1`);
                $('.js-video-overlay').find('iframe').fadeIn(300);
            }
            else{
                if (videoUrl.includes("/embed/")){
                    $('.js-video-overlay').find('iframe').attr('src', `${videoUrl}?autoplay=1`);
                    $('.js-video-overlay').find('iframe').fadeIn(300);
                }
            }
        }
        else {
            $('.js-video-overlay').find('video source').attr('src', videoUrl);
            $('.js-video-overlay').find('video').fadeIn(300);
            $('.js-video-overlay').find('video')[0].load();
            $('.js-video-overlay').find('video').on('canplay', function () {
                $('.js-video-overlay').find('video')[0].play();
            });
        }
        $('.js-video-overlay').fadeIn(300);
        scrollPosition = document.documentElement.scrollTop;
        $('body').addClass('no-scroll-desktop');
        $('body').css('top', `-${scrollPosition}px`);
    });

    $('.js-video-overlay').on('click', function (e) {
        const target = $(e.target);
        if (!target.hasClass('js-video-overlay-content') && target.parents('.js-video-overlay-content').length === 0) {
            $('.js-video-overlay').fadeOut(300);
            setTimeout(function() {
                $('.js-video-overlay').find('iframe').attr('src', '');
                $('.js-video-overlay').find('iframe').hide();
                $('.js-video-overlay').find('video source').attr('src', '');
                $('.js-video-overlay').find('video').hide();
            }, 300);
            $('body').removeClass('no-scroll-desktop');
            setTimeout(function() {
                $('body').css('top', 'auto');
                document.documentElement.scrollTop = scrollPosition;
            }, 10);
        }
    });

    $('.js-close-video-overlay').on('click', function () {
        $('.js-video-overlay').fadeOut(300);
        setTimeout(function() {
            $('.js-video-overlay').find('iframe').attr('src', '');
            $('.js-video-overlay').find('iframe').hide();
            $('.js-video-overlay').find('video source').attr('src', '');
            $('.js-video-overlay').find('video').hide();
        }, 300);
        $('body').removeClass('no-scroll-desktop');
        setTimeout(function() {
            $('body').css('top', 'auto');
            document.documentElement.scrollTop = scrollPosition;
        }, 10);
    });
};

export default videoOverlay;