const hero3 = function() {
    
    function setBackgroundHeight() {
        $('.js-hero3').each(function () {
            const isMobile = window.matchMedia('(max-width: 767px)').matches;
            const hero3 = $(this);
            const hero3Height = hero3.innerHeight();
            const topElementHeight = hero3.find('.js-hero3-top-el').innerHeight();
            const topElementTextHeight = hero3.find('.js-hero3-top-text').innerHeight();
            const topElementImgHeight = hero3.find('.js-hero3-top-img').innerHeight();
            let offsetTop = Math.round(topElementHeight - topElementImgHeight + (topElementImgHeight / 2) + parseInt($(this).css("padding-top")));
            if (isMobile) {
                offsetTop = Math.round(topElementHeight - topElementImgHeight - topElementTextHeight + (topElementImgHeight / 2) + parseInt($(this).css("padding-top")));
            }
            hero3.find('.js-hero3-bg')
                .css("height", `${hero3Height - offsetTop}px`)
                .removeClass('bg--hidden');
        });
    }

    setTimeout(function() {
        setBackgroundHeight();
    }, 100);
};

export default hero3;