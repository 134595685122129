const parallax = () => {

    $('.js-animate-zoom').each(function (i) {
        const img = $(this).parents('.img');
        const imgOffsetTop = img[0].getBoundingClientRect().top;
        if (imgOffsetTop - $(window).height() <= 0) {
            $(this).removeClass('img__wrapper--zoomed');
        } else {
            $(this).addClass('img__wrapper--zoomed');
        }
    });

    if (window.matchMedia('(min-width: 1024px)').matches) {
        $('.js-animate-offset').each(function () {
            const square = $(this);
            const squareOffsetTop = square.parent()[0].getBoundingClientRect().top;
            if (squareOffsetTop - $(window).height() <= -128) {
                square.removeClass('pushed-down');
            } else if (squareOffsetTop - $(window).height() >= 0) {
                square.addClass('pushed-down');
            }
        });
    }
};

export default parallax;