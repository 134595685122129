const axios = require('axios').default;
import { lazyLoading } from '../lazy-loading/lazyLoading';

export function handleNewsByCategory() {
    const newsCategoryEl = document.querySelectorAll('.js-news-category');
    newsCategoryEl.forEach(elem => {
        elem.addEventListener('click', (e) => {
            const { target } = e;
            const { dataset } = target;
            const { href } = dataset;

            if (href) {
                axios.get(href)
                    .then(function (response) {
                        const { data, status } = response;
                        if (status == 200 && data) {

                            if (document.getElementById('load-more-news-ajax-placeholder')) {
                                // removing the first (duplicated)
                                document.getElementById('load-more-news-ajax-placeholder').remove();
                            }

                            document.getElementById('news-listing').innerHTML = data;
                            const parser = new DOMParser();
                            const htmlResponse = parser.parseFromString(data, 'text/html');

                            const loadMoreEl = htmlResponse.querySelector('.js-news-load-more');
                            if (loadMoreEl) {
                                handleNewsLoadMore();
                            }

                            lazyLoading();
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
            }
        })
    })
};

export function handleNewsLoadMore() {
    const loadMoreEl = document.querySelector('.js-news-load-more');
    if (loadMoreEl) {
        loadMoreEl.addEventListener('click', (e) => {
            const { target } = e;
            const { dataset } = target;
            const { href } = dataset;

            if (href) {
                axios.get(href)
                    .then(function (response) {
                        const { data, status } = response;
                        if (status == 200 && data) {
                            // Remove current button, new one is returned from BE with the refresh data
                            document.getElementById('load-more-news-ajax-placeholder').remove();
                            // append response
                            document.getElementById('news-listing').innerHTML += data;
                            handleNewsLoadMore();
                            lazyLoading();
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
            }
        })
    }
}