const debounce = require('debounce');

const tabsToDropdownSwitch = function () {
    $('.js-tabs-dropdown-switch').each(function () {
        const tabsDropdown = $(this);
        const tabsWidth = tabsDropdown.find('.dropdown__list').width(); 

        if (tabsWidth > tabsDropdown.parent().width()) {
            tabsDropdown.removeClass('dropdown--tabs');
        }
        tabsDropdown.removeClass('dropdown--hidden');

        $(window).on('resize', debounce(function () {
            if (tabsWidth > tabsDropdown.parent().width()) {
                tabsDropdown.removeClass('dropdown--tabs');
            } else {
                tabsDropdown.addClass('dropdown--tabs');
            }
        }, 50));
    });

    
}

export default tabsToDropdownSwitch;