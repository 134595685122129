import Swiper from "swiper/dist/js/swiper.min";

function load(image) {
    const isSmallScreen = window.matchMedia('(max-width: 480px)').matches;
    const isMediumScreen = window.matchMedia('(min-width: 481px) and (max-width: 1023px)').matches;
    const isLargeScreen = window.matchMedia('(min-width: 1024px)').matches;
    let imageUrl = image.attr('data-bg');
    const isGif = imageUrl.indexOf('.gif') !== -1;
    const offsetTop = image[0].getBoundingClientRect().top
    const windowHeight = $(window).height();
    if (offsetTop <= (windowHeight * 1.5)) {
        if (isSmallScreen && image.attr('data-size-sm') && image.attr('data-loaded') !== 'sm' && !isGif) {
            if (image.attr('data-size-sm') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-sm')}`;
                image.attr('data-loaded', 'sm');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isMediumScreen && image.attr('data-size-md') && image.attr('data-loaded') !== 'md' && !isGif) {
            if (image.attr('data-size-md') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-md')}`;
                image.attr('data-loaded', 'md');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (isLargeScreen && image.attr('data-size-lg') && image.attr('data-loaded') !== 'lg' && !isGif) {
            if (image.attr('data-size-lg') !== 'hidden') {
                imageUrl = `${imageUrl.split('?')[0]}?width=${image.attr('data-size-lg')}`;
                image.attr('data-loaded', 'lg');
                image.css('background-image', `url("${imageUrl}")`);
            }
        } else if (!image.attr('data-loaded')) {
            image.attr('data-loaded', true);
            image.css('background-image', `url("${imageUrl}")`);
        }
    }
}

const articleSlider = function () {
    $(".article-slider .swiper-container").each(function () {
        const swiperContainer = $(this);
        const offsetTop = swiperContainer[0].getBoundingClientRect().top
        const windowHeight = $(window).height();
        if (offsetTop <= windowHeight * 1.5 && !swiperContainer.hasClass('swiper-container-initialized')) {
            initSwiper(swiperContainer);
        }
    });

    function initSwiper(swiperContainer) {
        const articleSwiper = new Swiper(swiperContainer, {
            init: false,
            preloadImages: false,
            speed: 600,
            lazy: false,
            slidesPerView: "auto",
            a11y: true,
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
                firstSlideMessage: "This is the first slide",
                lastSlideMessage: "This is the last slide",
                paginationBulletMessage: "Go to slide {{index}}",
            },
            scrollbar: {
                el: swiperContainer.parents('.article-slider').find(".swiper-scrollbar")
            },
            navigation: {
                nextEl: swiperContainer.parents('.article-slider').find(".swiper-button-next"),
                prevEl: swiperContainer.parents('.article-slider').find(".swiper-button-prev"),
            },
            on: {
                slideChange: function () {
                    if (window.matchMedia('(max-width: 1023px)').matches) {
                        if (articleSwiper.activeIndex + 2 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 2]).find('.img'));
                        }
                    } else {
                        if (articleSwiper.activeIndex + 3 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 3]).find('.img'));
                        }
                    }
                    swiperContainer.find('.swiper-slide').each(function () {
                        if ($(this).index() < articleSwiper.activeIndex) {
                            $(this).addClass('swiper-slide--hidden');
                        } else {
                            $(this).removeClass('swiper-slide--hidden');
                        }
                    })
                },
                resize: function () {
                    if (window.matchMedia('(max-width: 1023px)').matches) {
                        load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex]).find('.img'));
                        if (articleSwiper.activeIndex + 1 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 1]).find('.img'));
                        }
                        if (articleSwiper.activeIndex + 2 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 2]).find('.img'));
                        }
                    } else {
                        load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex]).find('.img'));
                        if (articleSwiper.activeIndex + 1 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 1]).find('.img'));
                        }
                        if (articleSwiper.activeIndex + 2 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 2]).find('.img'));
                        }
                        if (articleSwiper.activeIndex + 3 <= swiperContainer.find('.swiper-slide').length - 1) {
                            load($(swiperContainer.find('.swiper-slide')[articleSwiper.activeIndex + 3]).find('.img'));
                        }
                    }
                }
            }
        });
        articleSwiper.on('init', function () {
            swiperContainer.find('.swiper-slide').each(function (i) {
                const slide = $(this);
                if (window.matchMedia('(max-width: 1023px)').matches) {
                    if (i < 3) {
                        load(slide.find('.img'));
                    }
                } else {
                    if (i < 4) {
                        load(slide.find('.img'));
                    }
                }
            });
        });
        articleSwiper.init();
    }
};

const calcArticleSwiperBtnsPos = function () {
    $('.article-slider').each(function () {
        const img = $(this).find('.img')[0];
        const imgHeight = $(img).innerHeight();
        $(this).find('.article-slider__navigation').css('top', `${imgHeight / 2}px`);
    });
};

export { articleSlider, calcArticleSwiperBtnsPos };
