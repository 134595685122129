const cookieOverlay = () => {
    let scrollPositionCookie;

    function openPopup () {
        $('.js-cookie-popup').fadeIn(300);
        scrollPositionCookie = document.documentElement.scrollTop;
        $('body').addClass('no-scroll-desktop');
        $('body').css('top', `-${scrollPositionCookie}px`);
    }

    function closePopup () {
        $('.js-cookie-popup').fadeOut(300);
        $('body').removeClass('no-scroll-desktop');
        setTimeout(function() {
            $('body').css('top', 'auto');
            document.documentElement.scrollTop = scrollPositionCookie;
        }, 10);
    }

    const shouldOpen = $('.js-cookie-popup').attr('data-should-open');
    
    if (shouldOpen === 'True') {
        openPopup();
    }

    $('.js-cookie-popup-btn').on('click', function (e) {
        e.preventDefault();
        closePopup();
    });
};

export default cookieOverlay;