const productOverlay = () => {
    let scrollPosition;


    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function getCookieExpireDate() {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 1000 * 31536000; // +1 year
        now.setTime(expireTime);
        return now.toUTCString();
    }

    function handlePrefferedCulture() {
        const browserCulture = navigator.language;
        if (browserCulture.indexOf('-') === -1) {
            return `${browserCulture}-${browserCulture.toUpperCase()}`
        }
        return browserCulture;
    }

    function openPopup() {
        const prefferedCulture = handlePrefferedCulture();
        const culturePopupEl = $(`[data-culture=${prefferedCulture}]`);
        if (culturePopupEl.length > 0) {
            culturePopupEl.fadeIn(300);
            scrollPosition = document.documentElement.scrollTop;
            $('body').addClass('no-scroll-desktop');
            $('body').css('top', `-${scrollPosition}px`);
        }
    }

    function closePopup() {
        $('.js-culture-popup').fadeOut(300);
        $('body').removeClass('no-scroll-desktop');
        setTimeout(function () {
            $('body').css('top', 'auto');
            document.documentElement.scrollTop = scrollPosition;
        }, 10);
    }

    const userPreferredCulture = getCookie("userPreferredCulture");

    if (userPreferredCulture.length === 0) {
        openPopup();
    }

    $('.js-culture-popup-btn-redirect').on('click', function () {
        const preferredCulture = $(this).attr('data-preferred-culture');
        document.cookie = `userPreferredCulture=${preferredCulture}; expires=${getCookieExpireDate()}`;
    });

    $('.js-culture-popup-btn').on('click', function (e) {
        e.preventDefault();
        const preferredCulture = $(this).attr('data-preferred-culture');
        document.cookie = `userPreferredCulture=${preferredCulture}; expires=${getCookieExpireDate()}`;
        closePopup();
    });
};

export default productOverlay;