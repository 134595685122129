const venuesDropdown = () => {
    const vDD = document.querySelector('.dropdown--venue');
    const params = new URLSearchParams(window.location.search);
    const vid = params.get('vid');
    if (vDD && vid) {
        const selectedOption = vDD.querySelector('.dropdown__selected-option');
        const ddOptions = vDD.querySelectorAll('.dropdown__option');

        if (selectedOption && ddOptions.length > 0) {
            [...ddOptions].forEach(dd => {
                if (dd && dd.dataset.option == vid) {
                    selectedOption.textContent = dd.textContent;
                    selectedOption.setAttribute('data-selected', 'true');
                    dd.classList.add('selected')
                }
            })

        }
    }
};

export default venuesDropdown;