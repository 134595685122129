import Swiper from "swiper/dist/js/swiper.min";

const voucherSwiper = function () {
    $(".voucher-swiper .swiper-container").each(function () {
        const swiperContainer = $(this);
        const offsetTop = swiperContainer[0].getBoundingClientRect().top
        const windowHeight = $(window).height();
        const slideEl = swiperContainer.find('.swiper-slide');
        const slideElCount = slideEl.length;
        if (offsetTop <= windowHeight * 1.5 && !swiperContainer.hasClass('swiper-container-initialized')) {
            initSwiper(swiperContainer);
        }

        if (!(slideElCount > 1)) {
            swiperContainer.siblings('.voucher-swiper__navigation').hide()
        }
    });

    function initSwiper(swiperContainer) {
        const voucherSwiper = new Swiper(swiperContainer, {
            init: false,
            preloadImages: false,
            speed: 600,
            lazy: false,
            slidesPerView: 1,
            a11y: true,
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
                firstSlideMessage: "This is the first slide",
                lastSlideMessage: "This is the last slide",
                paginationBulletMessage: "Go to slide {{index}}",
            },
            scrollbar: {
                el: swiperContainer.parents('.voucher-swiper').find(".swiper-scrollbar")
            },
            navigation: {
                nextEl: swiperContainer.parents('.voucher-swiper').find(".swiper-button-next"),
                prevEl: swiperContainer.parents('.voucher-swiper').find(".swiper-button-prev"),
            },
            on: {
                slideChange: function () {
                    // stop and start autoplay of the videos
                    const { activeIndex, previousIndex, slides } = voucherSwiper;
                    const videos = swiperContainer.find('video');
                    videos[previousIndex].pause();
                    videos[activeIndex].play();
                },
            }
        });

        voucherSwiper.on('init', function () {
            const videos = swiperContainer.find('video');
            videos.each(function () {
                $(this).css({
                    width: '100%'
                })
            });

            const video = swiperContainer.find('video')[0];
            function checkforVideo() {
                //Every 500ms, check if the video element has loaded
                var b = setInterval(() => {
                    if (video.readyState >= 3) {
                        const video = swiperContainer.find('video')[0];
                        const videoHeight = $(video).innerHeight();
                        swiperContainer.next('.voucher-swiper__navigation').css('top', `${videoHeight / 2}px`);
                        clearInterval(b);
                    }
                }, 100);
            }
            checkforVideo();

        });
        voucherSwiper.init();
    }
};

export default voucherSwiper;