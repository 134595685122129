const debounce = require("debounce");
import axios from "axios";

function validateEmail(email) {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhoneNumber(number) {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return re.test(number);
}

class Form {
    constructor(
        formId,
        successMessageId,
        errorMessageId,
        uploadInputs,
        formData
    ) {
        this.form = $(formId);
        this.successMessage = $(successMessageId);
        this.errorMessage = $(errorMessageId);
        this.isFormValid = false;
        this.submitButton = this.form.find(".js-form-submit");
        this.formApi = window.location.origin + this.form.attr("data-api");
        this.formYandexMetricaTagId = this.form.attr("data-yandex-metrica-tag-id");
        this.formData = formData ? formData : {};
        for (let i = 0; i < uploadInputs.length; i++) {
            this[uploadInputs[i].uploadInputName] = uploadInputs[i].uploadInput;
        }
    }

    checkForInvalidInputs() {
        if (this.form.length) {
            const formElements = this.form[0].elements;
            for (let i = 0; i < formElements.length; i++) {
                const formEl = $(formElements[i]);
                if (formEl.is("input") || formEl.is("textarea")) {
                    if (formEl.attr("data-required")) {
                        if (
                            formEl.is("input[type=text]") ||
                            formEl.is("input[type=number]") ||
                            formEl.is("input[type=email]") ||
                            formEl.is("textarea")
                        ) {
                            if (formEl.val().length === 0) {
                                formEl.attr("data-is-valid", false);
                                this.isFormValid = false;
                            }
                        }
                        if (formEl.is("input[type=checkbox]")) {
                            if (!formEl.is(":checked")) {
                                formEl.attr("data-is-valid", false);
                                this.isFormValid = false;
                            }
                        }
                    }
                }
            }
        }
        if (!this.isFormValid) {
            this.submitButton.addClass("btn--disabled");
            this.submitButton.attr("tabindex", "-1");
        }
    }

    textboxErrorHandler(el, value) {
        if (
            el.is("input[type=text]") ||
            el.is("input[type=number]") ||
            el.is("input[type=email]") ||
            el.is("textarea")
        ) {
            if (el.attr("data-type") === "email" && value.length > 0) {
                if (!validateEmail(value)) {
                    el.addClass("textbox--error");
                    el.attr("data-is-valid", false);
                    const errorEl = el.next(".error");
                    errorEl.text(errorEl.attr("data-format-error"));
                    return;
                } else {
                    el.removeClass("textbox--error");
                    el.attr("data-is-valid", true);
                }
            }
            if (el.attr("data-type") === "number" && value.length > 0) {
                if (!validatePhoneNumber(value)) {
                    el.addClass("textbox--error");
                    el.attr("data-is-valid", false);
                    const errorEl = el.next(".error");
                    errorEl.text(errorEl.attr("data-format-error"));
                    return;
                } else {
                    el.removeClass("textbox--error");
                    el.attr("data-is-valid", true);
                }
            }

            if (el.attr("data-required")) {
                if (value.length > 0) {
                    el.removeClass("textbox--error");
                    el.attr("data-is-valid", true);
                } else {
                    el.addClass("textbox--error");
                    el.attr("data-is-valid", false);
                    const errorEl = el.next(".error");
                    errorEl.text(errorEl.attr("data-empty-error"));
                }
            } else {
                el.removeClass("textbox--error");
                el.attr("data-is-valid", true);
            }
        }
    }

    checkboxErrorHandler(el) {
        if (el.is("input[type=checkbox]") && el.attr("data-required")) {
            if (el.is(":checked")) {
                el.attr("data-is-valid", true);
            } else {
                el.attr("data-is-valid", false);
            }
        }
    }

    dropdownErrorHandler(el, value) {
        if (el.parent().hasClass("dropdown") && el.attr("data-required")) {
            if (value.length > 0) {
                el.attr("data-is-valid", true);
            }
        }
    }

    enableDisableSubmitButton() {
        const formElements = this.form[0].elements;
        for (let i = 0; i < formElements.length; i++) {
            const formEl = $(formElements[i]);
            if (formEl.is("input") || formEl.is("textarea")) {
                if (formEl.attr("data-is-valid") === "false") {
                    this.submitButton.addClass("btn--disabled");
                    this.submitButton.attr("tabindex", "-1");
                    return;
                } else {
                    this.submitButton.removeClass("btn--disabled");
                    this.submitButton.attr("tabindex", "0");
                }
            }
        }
    }

    doYandexMetricaRequest(formData, yandexMetricaTagId){
        if (typeof ym === 'undefined'){
            return;
        }

        let hasTagId = typeof yandexMetricaTagId !== 'undefined' && yandexMetricaTagId !== null && yandexMetricaTagId.trim() !== '';
        if (hasTagId === false){
            return;
        }

        let metrikaClientId = "";
        ym(yandexMetricaTagId, 'getClientID', function(clientID) {
            metrikaClientId = clientID;
        });

        let hasMetrikaClientId = typeof metrikaClientId !== 'undefined' && metrikaClientId !== null && metrikaClientId.trim() !== '';
        if (hasMetrikaClientId === false){
            return;
        }

        $.get("https://crm.wbooster.ru/index.php", {
            controller: 'seolead',
            token: '8242:d1ce6ca2b1a03070494b2f02454b47a1',
            metrika_client_id: metrikaClientId,
            name: formData.get("Name"),
            phone: formData.get("Phone"),
            email: formData.get("Email"),
            city: formData.get("City"),
            comment: formData.get("Message"),
            description: "Connect with us",
            url: window.location.href
        })
        .done(function (id) {
            if (id){
                ym(yandexMetricaTagId, 'params', {'wbooster': id});
            }
        });
    }

    formSubmitHandler() {
        const that = this;
        this.submitButton.on("click", function (e) {
            e.preventDefault();
            const formElements = that.form[0].elements;
            for (let i = 0; i < formElements.length; i++) {
                const formEl = $(formElements[i]);
                if (
                    (formEl.is("input") || formEl.is("textarea")) &&
                    !formEl.hasClass("js-no-form-data")
                ) {
                    if (
                        formEl.is("input[type=text]") ||
                        formEl.is("input[type=number]") ||
                        formEl.is("input[type=email]") ||
                        formEl.is("textarea")
                    ) {
                        if (formEl.hasClass("js-no-form-data-if-empty")) {
                            if (
                                (formEl.val().trim().length > 0 &&
                                    formEl.is(":visible")) ||
                                formEl.hasClass("js-hidden-form-data")
                            ) {
                                that.formData[formEl.attr("name")] =
                                    formEl.val();
                            }
                        } else {
                            that.formData[formEl.attr("name")] = "";
                            if (
                                formEl.is(":visible") ||
                                formEl.hasClass("js-hidden-form-data")
                            ) {
                                that.formData[formEl.attr("name")] =
                                    formEl.val();
                            }
                        }
                        if (formEl.attr("data-option-id")) {
                            that.formData[`${formEl.attr("name")}Id`] =
                                formEl.attr("data-option-id");
                        }
                    }

                    if (formEl.is("input[type=radio]")) {
                        if (
                            $(
                                `input[type=radio][name=${formEl.attr(
                                    "name"
                                )}]:checked:visible`
                            ).length > 0
                        ) {
                            if (
                                formEl.is(":checked") &&
                                formEl.is(":visible")
                            ) {
                                that.formData[formEl.attr("name")] =
                                    formEl.val();
                            }
                        } else {
                            that.formData[formEl.attr("name")] = "";
                        }
                    }

                    if (formEl.is("input[type=checkbox]")) {
                        that.formData[formEl.attr("name")] =
                            formEl.is(":checked");
                    }

                    if (formEl.is("input[type=file]")) {
                        that.formData[formEl.attr("name")] = [];
                        if (formEl.is(":visible")) {
                            that.formData[formEl.attr("name")] =
                                that[formEl.attr("name")].getUploadedFiles();
                        }
                    }
                }
            }
            let formData = new FormData();
            for (const property in that.formData) {
                if (typeof that.formData[property] === "object") {
                    for (let i = 0; i < that.formData[property].length; i++) {
                        if (that.formData[property][i].constructor === File) {
                            formData.append(
                                property,
                                that.formData[property][i]
                            );
                        }
                    }
                } else {
                    formData.append(property, that.formData[property]);
                }
            }

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            that.submitButton.addClass("btn--disabled");

            axios
                .post(that.formApi, formData, config)
                .then((response) => {
                    that.form.hide();
                    that.successMessage.show();
                    document.documentElement.scrollTop =
                        that.successMessage.parent().offset().top -
                        $("nav").innerHeight();
                    that.submitButton.removeClass("btn--disabled");

                    /* Google Analytics */
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: "Google Analytics Event",
                        eventCategory: "Contact Form",
                        eventAction: "Submit",
                        eventLabel: `${that.formData.SubjectPrimary}${
                            that.formData.SubjectSecondary.length
                                ? ` - ${that.formData.SubjectSecondary}`
                                : ""
                        }`,
                        campoEmail: that.formData.Email,
                        campoTelefono: that.formData.Phone
                    });
                })
                .catch((error) => {
                    that.errorMessage.show();
                    that.submitButton.removeClass("btn--disabled");
                });

            let culture = formData.get("Country");
            let isRussianCulture = culture === "ru-RU" || culture === "ru-ru";
            if (isRussianCulture === true){
                let subjectPrimaryId = formData.get("SubjectPrimaryId");
                let isBusinessOutlet = subjectPrimaryId === "BusinessOutlet";
                if (isBusinessOutlet === true){
                    that.doYandexMetricaRequest(formData, that.formYandexMetricaTagId);
                }
            }
            else{
                that.doYandexMetricaRequest(formData, that.formYandexMetricaTagId);
            }
        });
    }

    showRelatedFields(el) {
        if (el.hasClass("js-show-related-fields")) {
            let selectedOptionId = el.attr("id");
            if (el.parents(".dropdown").length > 0) {
                selectedOptionId = el
                    .parents(".dropdown")
                    .find(".dropdown__option.selected")
                    .attr("id");
            }
            $(`[data-related-id]`).each(function () {
                const dataRelatedId = $(this)
                    .attr("data-related-id")
                    .split(",");
                if (dataRelatedId.indexOf(selectedOptionId) > -1) {
                    $(this).show();
                } else {
                    $(this).hide();
                    if ($(this).find("input[type=radio]").length) {
                        $(this)
                            .find("input[type=radio]")
                            .prop("checked", false);
                    }
                }
            });
        }
    }

    init() {
        const that = this;
        this.checkForInvalidInputs();
        this.formSubmitHandler();

        this.form.on(
            "input",
            debounce(function (e) {
                const el = $(e.target);
                that.textboxErrorHandler(el, e.target.value);
                that.enableDisableSubmitButton();
            }, 250)
        );

        this.form.on("change", function (e) {
            const el = $(e.target);
            that.textboxErrorHandler(el, e.target.value);
            that.checkboxErrorHandler(el);
            that.dropdownErrorHandler(el, e.target.value);
            that.enableDisableSubmitButton();
            that.showRelatedFields(el);
        });
    }
}

export { Form };
