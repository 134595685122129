import navigation from "./navigation/navigation";
import { lazyLoading } from "./lazy-loading/lazyLoading";
import parallax from "./parallax/parallax";
import dropdown from "./dropdown/dropdown";
import { heroSlider, lazyLoadHeroSlider } from "./widgets/hero-slider/heroSlider";
import hero2 from "./widgets/hero-2/hero-2";
import background from "./backgrounds/background";
import simpleColTwoTwo from "./widgets/simple-col-2-2/simple-col-2-2";
import hero3 from "./widgets/hero-3/hero-3";
import { articleSlider, calcArticleSwiperBtnsPos } from "./widgets/article-slider/article-slider";
import calculateSplitHeaderPaddingBottom from "./split-header/split-header";
import productSlider from "./product-slider/product-slider";
import searchOverlay from "./search-overlay/search-overlay";
import videoOverlay from "./video-overlay/video-overlay";
import threeRedBox from "./widgets/3-red-box/3-red-box";
import headerSlider from "./widgets/header-slider/header-slider";
import tabsToDropdownSwitch from "./tabs-to-dropdown-switch/tabs-to-dropdown-switch";
import { tabsWidget, calculateTabsWidgetBgWidth } from "./widgets/tabs-widget/tabs-widget";
import socialMediaShare from "./social-media-share/social-media-share";
import contactForm from "./contact-form/contact-form";
import videoWidget from "./widgets/video-widget/video-widget";
import pressForm from "./press-form/press-form";
import accessibility from "./accessibility/accessibility";
import culturePopup from "./culture-popup/culture-popup";
import cookiePopup from "./cookie-popup/cookie-popup";
import coffeeProductsFilters from "./coffee-products-filters/coffee-products-filters";
import imageSlider from "./widgets/image-slider/image-slider";
import { handleNewsByCategory, handleNewsLoadMore } from "./news/news";
import { handleSearchLoadMore } from "./search-results/search-results";
import voucherSwiper from "./widgets/voucher/voucher-swiper";
import venuesDropdown from "./widgets/venues/venues-dropdown";
import popupWidget from "./widgets/popup-widget/popupWidget";
import {
    youMayAlsoLikeWidget,
    youMayAlsoLikeWidgetBtnsPos,
} from "./widgets/you-may-also-like-widget/you-may-also-like-widget";
import stickyMenuWidget from "./widgets/sticky-menu/sticky-menu";
import {baristaCampaign, validateMultiStepForm} from "./barista-campaign/barista-campaign"

const debounce = require("debounce");

const modules = () => {
    $(() => {
        $("body").removeClass("css-transitions-only-after-page-load");
        culturePopup();
        cookiePopup();
        lazyLoading();
        navigation();
        calculateSplitHeaderPaddingBottom();
        parallax();
        contactForm();
        dropdown();
        heroSlider();
        hero2();
        background();
        simpleColTwoTwo();
        hero3();
        articleSlider();
        calcArticleSwiperBtnsPos();
        productSlider();
        searchOverlay();
        videoOverlay();
        threeRedBox();
        headerSlider();
        tabsToDropdownSwitch();
        tabsWidget();
        calculateTabsWidgetBgWidth();
        socialMediaShare();
        videoWidget();
        pressForm();
        accessibility();
        coffeeProductsFilters();
        imageSlider();
        handleNewsByCategory();
        handleNewsLoadMore();
        handleSearchLoadMore();
        voucherSwiper();
        venuesDropdown();
        popupWidget();
        youMayAlsoLikeWidget();
        youMayAlsoLikeWidgetBtnsPos();
        stickyMenuWidget();
        baristaCampaign();

        $(window).on(
            "resize",
            debounce(function () {
                hero2();
                lazyLoading();
                calculateSplitHeaderPaddingBottom();
                background();
                hero3();
                simpleColTwoTwo();
                calcArticleSwiperBtnsPos();
                threeRedBox();
                calculateTabsWidgetBgWidth();
                youMayAlsoLikeWidgetBtnsPos();
            }, 50)
        );

        $(window).on(
            "scroll",
            debounce(function () {
                lazyLoading();
                lazyLoadHeroSlider();
                articleSlider();
                tabsWidget();
                videoWidget();
                voucherSwiper();
                youMayAlsoLikeWidget();
            }, 20)
        );

        $(window).on("scroll", function () {
            stickyMenuWidget();
        });

        $(window).on("scroll", function () {
            parallax();
        });

        window.lazyLoading = lazyLoading;
        window.headerSliderInit = headerSlider;
        window.backgroundInit = background;
        window.heroSliderInit = heroSlider;
        window.articleSliderInit = articleSlider;
        window.voucherSwiperInit = voucherSwiper;
        window.youMayAlsoLikeWidgetInit = youMayAlsoLikeWidget;
        window.validateMultiStepForm = validateMultiStepForm;
    });
};

export default modules;
